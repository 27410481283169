import React from 'react'
import { forwardRef } from 'react'

type Props = { classname?: string } & React.HTMLAttributes<HTMLDivElement>
const PageLayout = forwardRef<HTMLDivElement, Props>(({ className, ...props }, ref) => (
  <div ref={ref} className={`mx-auto flex w-full max-w-[450px] flex-col pt-[40px] ${className ?? ''}`} {...props}>
    {props.children}
  </div>
))
PageLayout.displayName = 'PageLayout'

export default PageLayout
