import { useCallback, useEffect } from 'react'
import { ALL_DEXES } from '../../constants/pool'
import { useAppDispatch, useAppSelector } from '../hooks'
import { setLiquiditySource } from './user'

export const useLiquiditySource = (resetWhenInit = false) => {
  const { liquiditySource = '' } = useAppSelector((state) => state.user)
  const dispatch = useAppDispatch()

  const onSetState = useCallback(
    (vl: string) => {
      dispatch(setLiquiditySource(vl))
    },
    [dispatch],
  )

  useEffect(() => {
    if (resetWhenInit) onSetState(ALL_DEXES.join(','))
  }, [onSetState, resetWhenInit])

  const totalSource = liquiditySource.split(',').length
  return {
    source: liquiditySource,
    totalSource,
    setLiquiditySource: onSetState,
  }
}
