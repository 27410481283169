import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { APP_VERSION, DEFAULT_SLIPPAGE_BPS } from '../../constants'
import { PURGE } from 'redux-persist'
import { PartialRecord } from '../../types'

export interface ITransactionHistory {
  txHash: string | undefined
  isSuccess: boolean
  details: string | undefined
  tokenInSymbol: string | undefined
  tokenOutSymbol: string | undefined
  readableAmountIn: string
  readableAmountOut: string
  timestamp: number
  tokenInAddress: string
  tokenOutAddress: string
}

export interface UserState {
  version: number
  slippageBps: number
  dirtySlippage: string
  liquiditySource: string
  txHistoryMap: PartialRecord<string, ITransactionHistory>
}

const initialState: UserState = {
  version: APP_VERSION,
  slippageBps: DEFAULT_SLIPPAGE_BPS,
  dirtySlippage: '',
  liquiditySource: '',
  txHistoryMap: {},
}

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setSlippageBps: (state, action: PayloadAction<number>) => {
      state.slippageBps = action.payload
    },
    setDirtySlippage: (state, action: PayloadAction<string>) => {
      state.dirtySlippage = action.payload
    },
    setLiquiditySource: (state, action: PayloadAction<string>) => {
      state.liquiditySource = action.payload
    },
    addTransactionHistory: (state, action: PayloadAction<ITransactionHistory>) => {
      if (!action.payload.txHash) return

      if (state.txHistoryMap) {
        state.txHistoryMap[action.payload.txHash] = action.payload
        return
      }
      state.txHistoryMap = {
        [action.payload.txHash]: action.payload,
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(PURGE, () => {
      return initialState
    })
  },
})

export const { setSlippageBps, setDirtySlippage, addTransactionHistory, setLiquiditySource } = userSlice.actions

export default userSlice.reducer
