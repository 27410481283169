import { motion } from 'framer-motion'
import { useEffect } from 'react'

const mounted: Record<string, boolean> = {}
const duration = 0.2
export default function MainMotion({
  children,
  skipInitialAnimation = '',
  className,
}: {
  children: React.ReactNode
  skipInitialAnimation?: string // unique id, key to mark if animation should be skipped
  className?: string
}) {
  const shouldPlayAnim = skipInitialAnimation ? mounted[skipInitialAnimation] : true

  useEffect(() => {
    setTimeout(() => {
      mounted[skipInitialAnimation] = true
    }, duration)
  }, [skipInitialAnimation])

  return (
    <motion.div
      className={className}
      initial={shouldPlayAnim ? { translateX: 100, opacity: 0 } : false}
      animate={{ translateX: 0, opacity: 1 }}
      transition={{ duration: duration }}
    >
      {children}
    </motion.div>
  )
}
