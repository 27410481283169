import { Button } from '@nextui-org/react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Icon } from '@iconify/react'
import { IS_SUPPORT_POOL } from '@/constants/limitOrder.ts'

export function Menu() {
  const location = useLocation()
  const navigate = useNavigate()

  const menuItems = [
    { name: 'Swap', path: '/swap/MOVE-USDC', icon: 'mdi:swap-horizontal', contain: 'swap' },
    { name: 'Liquidity', path: '/liquidity/my-positions', icon: 'mdi:water', contain: 'liquidity' },
  ]

  if (!IS_SUPPORT_POOL) return null

  return (
    <div className="flex items-center gap-1.5">
      {menuItems.map((item, index) => (
        <Button
          key={index}
          variant="light"
          color={location.pathname.includes(item.contain) ? 'primary' : 'default'}
          className={
            'flex h-fit  w-fit min-w-fit justify-start gap-2 px-3 py-1.5 data-[hover]:bg-transparent' +
            (location.pathname.includes(item.contain) ? 'border-1 border-primary text-primary' : 'text-baseGrey')
          }
          disableRipple
          isIconOnly
          disableAnimation
          onPress={() => {
            navigate(item.path)
          }}
        >
          <Icon icon={item.icon} width={20} className="mr-1" />
          {item.name}
        </Button>
      ))}
    </div>
  )
}
