import { Asset, MOVE, USDC } from '../../constants/asset'
import { useAppSelector } from '../../redux/hooks'
import { useMemo } from 'react'

const findFn = (token: Asset | undefined, tokenSymbolOrAddress0: string) => {
  try {
    return (
      token?.symbol === tokenSymbolOrAddress0 ||
      token?.id === tokenSymbolOrAddress0 ||
      token?.coinType === tokenSymbolOrAddress0
    )
  } catch {
    return false
  }
}

export function useTokenInOutInfo(tokenSymbolOrAddress0: string, tokenSymbolOrAddress1: string) {
  const followingTokenData = useAppSelector((state) => state.token.followingTokenData)
  const tokenIn = useMemo(() => {
    const token = Object.values(followingTokenData).find((token) => {
      return findFn(token, tokenSymbolOrAddress0)
    })
    return token?.id || MOVE?.id || ''
  }, [followingTokenData, tokenSymbolOrAddress0])
  const tokenOut = useMemo(() => {
    const token = Object.values(followingTokenData).find((token) => {
      return findFn(token, tokenSymbolOrAddress1)
    })
    return token?.id || USDC?.id || ''
  }, [followingTokenData, tokenSymbolOrAddress1])

  const tokenInInfo: Asset | undefined = useMemo(() => followingTokenData[tokenIn], [followingTokenData, tokenIn])
  const tokenOutInfo: Asset | undefined = useMemo(() => followingTokenData[tokenOut], [followingTokenData, tokenOut])

  return useMemo(
    () => ({
      tokenIn,
      tokenOut,
      tokenInInfo,
      tokenOutInfo,
      tokenInDecimals: tokenInInfo?.decimals,
      tokenOutDecimals: tokenOutInfo?.decimals,
    }),
    [tokenIn, tokenOut, tokenInInfo, tokenOutInfo],
  )
}
