import { useCallback } from 'react'
import { Fraction } from '../../../utils/fraction.ts'

export function useSetPercentAmountIn(fractionalBalanceTokenIn: Fraction | undefined, fractionalFeeAmount: Fraction | undefined, tokenInDecimals: number | undefined) {
  return useCallback((percent: number, setTypedAmountIn: (amount: string, decimals?: number) => void) => {
    if (fractionalBalanceTokenIn && fractionalFeeAmount) {
      let newTypedAmountIn = fractionalBalanceTokenIn.multiply(percent).divide(100)
      if (fractionalBalanceTokenIn.subtract(fractionalFeeAmount).lessThan(newTypedAmountIn)) {
        newTypedAmountIn = newTypedAmountIn.subtract(fractionalFeeAmount)
      }
      if (newTypedAmountIn.greaterThan(0)) {
        const newTypedAmountInStr = newTypedAmountIn.toFixed(18)
        setTypedAmountIn(newTypedAmountInStr, tokenInDecimals)
      } else {
        setTypedAmountIn('', tokenInDecimals)
      }
    } else {
      setTypedAmountIn('', tokenInDecimals)
    }
  }, [fractionalBalanceTokenIn, fractionalFeeAmount, tokenInDecimals])
}