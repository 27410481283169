import useSWR from 'swr'
import { useCallback, useMemo } from 'react'
import { AMM_CONTRACT, aptos } from '@/constants'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { Asset } from '@/constants/asset'

interface PoolData {
  fee: string // Represented as string if it includes decimals or may need exact precision
  is_locked: boolean
  is_stable: boolean
  pool_addr: string
  protocol_fee: string // Same reasoning as 'fee'
  token_x: string
  token_x_reserve: string // Typically large numbers, keeping as string for precision
  token_y: string
  token_y_reserve: string // Same reasoning as 'token_x_reserve'
  x_scale: string // Assuming scale values are strings as per the given data
  y_scale: string
}

export interface GetLiqPoolsResponseData {
  poolAddr: string
  tokenX: string
  tokenY: string
  isStable: boolean
  isLocked: boolean
  fee: string
  protocolFee: string
  tokenXReserve: string
  tokenYReserve: string
  tvlUsd: string
  dailyVolumeUsd: string
  apr: string
  createdAt: string
  updatedAt: string
  deletedAt: string
}

export interface GetLiqPoolsResponse {
  data: {
    result: GetLiqPoolsResponseData[]
    pagination: {
      page: number
      size: number
      total: number
    }
  }
}

const getPoolDetail = async (poolAddr: string): Promise<PoolData> => {
  const result: PoolData[] = await aptos.viewJson({
    payload: {
      function: `${AMM_CONTRACT}::liquidity_pool::get_pool_view`,
      functionArguments: [poolAddr],
      typeArguments: [],
    },
  })
  return result[0]
}

export const getAllPools = async () => {
  const result: Array<Array<{ inner: string }>> = await aptos.viewJson({
    payload: {
      function: `${AMM_CONTRACT}::liquidity_pool::all_pools`,
      functionArguments: [],
      typeArguments: [],
    },
  })
  const poolAddresses = result[0].map((e) => e.inner)
  const list = await Promise.all(poolAddresses.map((e) => getPoolDetail(e)))
  return list
}

const fn = async ({ page, size }: { page: number; size: number }) => {
  // const response = await axios<GetLiqPoolsResponse>(`${AMM_URL}/api/v1/pools`, {
  //   params: {
  //     page,
  //     size,
  //   },
  // })
  const data = await getAllPools()
  return {
    result: data.map((e) => ({
      ...e,
      isStable: e.is_stable,
      tokenY: e.token_y,
      tokenX: e.token_x,
    })),
    pagination: {
      page,
      size,
      total: data.length,
    },
  }
}

export function useGetLiqPools(page = 1, size = 10) {
  const {
    data: getLiqPools,
    error,
    isLoading: isValidating,
    mutate,
  } = useSWR(
    {
      key: `getLiqPools-${page}-${size}`,
      page,
      size,
    },
    fn,
  )
  return useMemo(() => {
    return { getLiqPools, error, isValidating, reFetch: mutate }
  }, [getLiqPools, error, isValidating, mutate])
}

export const useGetPoolFromUrl = () => {
  const [searchParams] = useSearchParams()
  return { pool: searchParams.get('pool') ?? '' }
}

export const useNavigateToPool = () => {
  const navigate = useNavigate()

  const navigateToAddLiq = useCallback(
    ({ pool, token0, token1 }: { pool: string; token0: Asset | undefined; token1: Asset | undefined }) => {
      navigate(`/liquidity/add/${token0?.symbol}-${token1?.symbol}?pool=${pool}`)
    },
    [navigate],
  )
  const navigateToRemoveLiq = useCallback(
    ({ pool, token0, token1 }: { pool: string; token0: Asset | undefined; token1: Asset | undefined }) => {
      navigate(`/liquidity/remove/${token0?.symbol}-${token1?.symbol}?pool=${pool}`)
    },
    [navigate],
  )

  return {
    navigateToAddLiq,
    navigateToRemoveLiq,
  }
}
