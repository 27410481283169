import { Button, Spacer } from '@nextui-org/react'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { useDebounceValue } from 'usehooks-ts'
import { SettingIcon } from '../../components/Icons.tsx'
import CountdownSpinner from '../../components/CountdownSpinner.tsx'
import { BIP_BASE } from '../../constants'
import useFullTokens from '../../hooks/useFullTokens.ts'
import useQuote from '../../hooks/useQuote.ts'
import useSwap from '../../hooks/useSwap.tsx'
import { useAppDispatch, useAppSelector } from '../../redux/hooks'
import { addTokensToFollow } from '../../redux/slices/token.ts'
import { Fraction } from '../../utils/fraction.ts'
import {
  divpowToFraction,
  escapeRegExp,
  inputRegex,
  mulpowToFraction,
  numberWithCommas,
  truncateValue,
} from '../../utils/number.ts'
import useMovementNetworkStatus from '../../hooks/useMovementNetworkStatus.ts'
import TokenSelector from '../../components/TokenSelector.tsx'
import SwapSettings from './SwapSettings.tsx'
import ModalTradeRoute from '../../components/modals/ModalTradeRoute.tsx'
import { useModal } from '../../provider/ModalProvider.tsx'
import { MODAL_LIST } from '../../components/modals/constant.ts'
import { SwapPanel } from './constant.ts'
import { Asset, MOVE, USDC } from '../../constants/asset.ts'
import useMovementWallet from '../../hooks/useMovementWallet.ts'
import { useAnimationControls } from 'framer-motion'
import Announcement from '../../components/Announcement.tsx'
import { useTokenBalance } from '../../hooks/useRefreshBalanceFn.ts'
import DexSelector from './DexSelector.tsx'
import { useLiquiditySource } from '../../redux/slices/hooks.ts'
import { ALL_DEXES } from '../../constants/pool.ts'
import { SwapLimitTab } from '../../components/SwapLimitTab.tsx'
import { SwapForm } from '@/components/swap/SwapForm.tsx'
import { IS_SUPPORT_LO } from '@/constants/limitOrder.ts'

export const SwapHeaderContent = () => {
  return null
  return (
    <div className="mt-[20px] w-full">
      <Announcement />
    </div>
  )
}

export default function SwapPage() {
  const { globalModal, isModalOpen, onOpenModal, onCloseModal, onOpenChangeModal } = useModal()

  const { account, connected } = useMovementWallet()

  const dispatch = useAppDispatch()

  const location = useLocation()
  const navigate = useNavigate()
  const [params] = useSearchParams()

  const resetTimerFunction = useRef(() => {})
  const setResetTimerFunc = (f: () => void) => (resetTimerFunction.current = f)

  const { isNetworkStable, isLoadingNetworkStatus } = useMovementNetworkStatus()

  const [typedAmountIn, _setTypedAmountIn] = useState('1')
  const [shouldUseDebounceAmountIn, setShouldUseDebounceAmountIn] = useState(true)
  const setTypedAmountIn = useCallback((value: string, decimals = 8, shouldUseDebounce = true) => {
    setShouldUseDebounceAmountIn(shouldUseDebounce)
    if (value?.endsWith(',')) {
      value = value.slice(0, value.length - 1) + '.'
    }
    value = value.replaceAll(',', '')
    if (value === '' || inputRegex.test(escapeRegExp(value))) {
      value = truncateValue(value, decimals)
      if (value.length && value.startsWith('.')) value = '0.'
      value = numberWithCommas(value)
      _setTypedAmountIn(value)
    }
  }, [])

  const followingTokenData = useAppSelector((state) => state.token.followingTokenData)
  const tokenIn = useMemo(
    () =>
      (Object.values(followingTokenData) as Asset[]).find((token) => {
        try {
          const tokenSymbolOrAddress = location.pathname.replace('/swap/', '').split('-')[0]
          return token.symbol === tokenSymbolOrAddress || token.id === tokenSymbolOrAddress
        } catch {
          return false
        }
      })?.id || MOVE.id,
    [followingTokenData, location.pathname],
  )
  const tokenOut = useMemo(
    () =>
      (Object.values(followingTokenData) as Asset[]).find((token) => {
        try {
          const tokenSymbolOrAddress = location.pathname.replace('/swap/', '').split('-')[1]
          return token.symbol === tokenSymbolOrAddress || token.id === tokenSymbolOrAddress
        } catch {
          return false
        }
      })?.id || USDC.id,
    [followingTokenData, location.pathname],
  )

  const [_tokenInInfo, setTokenInInfo] = useState<Asset>()
  const [_tokenOutInfo, setTokenOutInfo] = useState<Asset>()
  const tokenInInfo: Asset | undefined = useMemo(
    () => _tokenInInfo || followingTokenData[tokenIn],
    [followingTokenData, tokenIn, _tokenInInfo],
  )
  const tokenOutInfo: Asset | undefined = useMemo(
    () => _tokenOutInfo || followingTokenData[tokenOut],
    [followingTokenData, tokenOut, _tokenOutInfo],
  )

  const { data: fullTokenData } = useFullTokens()
  useEffect(() => {
    const pair = location.pathname.replace('/swap/', '')
    try {
      const tokenInSymbolOrAddress = pair.split('-')[0]
      const tokenOutSymbolOrAddress = pair.split('-')[1]
      if (!tokenInSymbolOrAddress || !tokenOutSymbolOrAddress) throw new Error(`invalid pair = ${pair}`)

      const followingTokenDataList = Object.values(followingTokenData) as Asset[]

      if (!fullTokenData || Object.values(fullTokenData).length === 0) return
      const fullTokenDataList = Object.values(fullTokenData) as Asset[]

      const findFn = (symbolOrAddress: string) =>
        fullTokenDataList.find((token) => token.id === symbolOrAddress) ||
        followingTokenDataList.find((token) => token.symbol === symbolOrAddress || token.id === symbolOrAddress)

      const newTokenIn = findFn(tokenInSymbolOrAddress)
      const newTokenOut = findFn(tokenOutSymbolOrAddress)

      if (!newTokenIn) throw new Error(`cannot find tokenIn = ${tokenInSymbolOrAddress}`)
      if (!newTokenOut) throw new Error(`cannot find tokenOut = ${tokenOutSymbolOrAddress}`)
      dispatch(addTokensToFollow([newTokenIn.id, newTokenOut.id]))
      setTokenInInfo(undefined)
      setTokenOutInfo(undefined)
    } catch (err) {
      pair !== '/swap' && console.error(err)
      navigate(`/swap/MOVE-USDC?${params.toString()}`, { replace: true })
    }
  }, [dispatch, followingTokenData, fullTokenData, location.pathname, navigate, params])

  const _setTokenIn = useCallback(
    (symbolOrAddress: string) => {
      const pair = location.pathname.replace('/swap/', '')
      try {
        const tokenInSymbolOrAddress = pair.split('-')[0]
        const tokenOutSymbolOrAddress = pair.split('-')[1]
        if (!tokenInSymbolOrAddress || !tokenOutSymbolOrAddress) throw new Error(`invalid pair = ${pair}`)
        navigate(`/swap/${symbolOrAddress}-${tokenOutSymbolOrAddress}?${params.toString()}`, { replace: true })
      } catch (err) {
        pair !== '/swap' && console.error(err)
        navigate(`/swap/MOVE-USDC?${params.toString()}`, { replace: true })
      }
    },
    [location.pathname, navigate, params],
  )
  const _setTokenOut = useCallback(
    (symbolOrAddress: string) => {
      const pair = location.pathname.replace('/swap/', '')
      try {
        const tokenInSymbolOrAddress = pair.split('-')[0]
        const tokenOutSymbolOrAddress = pair.split('-')[1]
        if (!tokenInSymbolOrAddress || !tokenOutSymbolOrAddress) throw new Error(`invalid pair = ${pair}`)
        navigate(`/swap/${tokenInSymbolOrAddress}-${symbolOrAddress}?${params.toString()}`, { replace: true })
      } catch (err) {
        pair !== '/swap' && console.error(err)
        navigate(`/swap/MOVE-USDC?${params.toString()}`, { replace: true })
      }
    },
    [location.pathname, navigate, params],
  )

  const tokenInDecimals = tokenInInfo ? tokenInInfo.decimals : undefined
  const tokenOutDecimals = tokenOutInfo ? tokenOutInfo.decimals : undefined

  const followingPriceData = useAppSelector((state) => state.price.followingPriceData)
  const fractionalPriceTokenIn = useMemo(
    () => (followingPriceData[tokenIn] ? mulpowToFraction(followingPriceData[tokenIn]) : undefined),
    [followingPriceData, tokenIn],
  )
  const fractionalPriceTokenOut = useMemo(
    () => (followingPriceData[tokenOut] ? mulpowToFraction(followingPriceData[tokenOut]) : undefined),
    [followingPriceData, tokenOut],
  )

  const balanceTokenIn = useTokenBalance(tokenInInfo)
  const fractionalBalanceTokenIn = useMemo(
    () =>
      balanceTokenIn && tokenInDecimals !== undefined ? divpowToFraction(balanceTokenIn, tokenInDecimals) : undefined,
    [balanceTokenIn, tokenInDecimals],
  )
  const balanceTokenOut = useTokenBalance(tokenOutInfo)

  const fractionalBalanceTokenOut = useMemo(
    () =>
      balanceTokenOut && tokenOutDecimals !== undefined
        ? divpowToFraction(balanceTokenOut, tokenOutDecimals)
        : undefined,
    [balanceTokenOut, tokenOutDecimals],
  )

  const _fractionalAmountIn = useMemo(
    () =>
      typedAmountIn && tokenInDecimals !== undefined
        ? mulpowToFraction(typedAmountIn.replaceAll(',', ''), tokenInDecimals)
        : undefined,
    [tokenInDecimals, typedAmountIn],
  )
  const [fractionalAmountIn] = useDebounceValue(_fractionalAmountIn, shouldUseDebounceAmountIn ? 100 : 0)

  const { source, setLiquiditySource: setSource, totalSource } = useLiquiditySource(true)

  const slippageBps = useAppSelector((state) => state.user.slippageBps)

  const { isSwapping, onSwap: _onSwap } = useSwap()

  const {
    dstAmount,
    isValidating: isValidatingQuote,
    paths,
    swapData,
    reFetch,
  } = useQuote(
    isSwapping,
    account?.address,
    tokenIn,
    tokenOut,
    fractionalAmountIn?.numerator?.toString(),
    slippageBps,
    totalSource === ALL_DEXES.length ? '' : source,
  )
  const fractionalAmountOut = useMemo(
    () => (dstAmount && tokenOutDecimals ? divpowToFraction(dstAmount, tokenOutDecimals) : undefined),
    [dstAmount, tokenOutDecimals],
  )

  const readableAmountOut =
    fractionalAmountOut && tokenOutDecimals !== undefined
      ? numberWithCommas(truncateValue(fractionalAmountOut.toFixed(18), tokenOutDecimals))
      : ''

  const fractionalAmountInUsd = useMemo(
    () =>
      fractionalAmountIn && fractionalPriceTokenIn ? fractionalAmountIn.multiply(fractionalPriceTokenIn) : undefined,
    [fractionalAmountIn, fractionalPriceTokenIn],
  )
  const fractionalAmountOutUsd = useMemo(
    () =>
      fractionalAmountOut && fractionalPriceTokenOut
        ? fractionalAmountOut.multiply(fractionalPriceTokenOut)
        : undefined,
    [fractionalAmountOut, fractionalPriceTokenOut],
  )

  const rate = useMemo(
    () => (fractionalAmountIn && fractionalAmountOut ? fractionalAmountOut.divide(fractionalAmountIn) : undefined),
    [fractionalAmountIn, fractionalAmountOut],
  )
  const priceImpact = useMemo(() => {
    let res =
      fractionalAmountInUsd && fractionalAmountOutUsd
        ? fractionalAmountInUsd.subtract(fractionalAmountOutUsd).divide(fractionalAmountInUsd).multiply(100)
        : undefined
    if (res?.lessThan(0)) {
      res = new Fraction(0)
    }
    return res
  }, [fractionalAmountInUsd, fractionalAmountOutUsd])
  const isPriceImpactVeryHigh = useMemo(() => Boolean(priceImpact?.greaterThan(10)), [priceImpact])
  const isPriceImpactHigh = useMemo(() => Boolean(priceImpact?.greaterThan(5)), [priceImpact])

  const minimumReceived = useMemo(() => {
    if (!fractionalAmountOut) return undefined
    // If any tokens have more than 8 decimals, this assignment will break. I assume 8 is the max decimals in aptos chain? Never mind, I will use 18.
    const str = fractionalAmountOut
      .multiply(BIP_BASE - slippageBps)
      .divide(BIP_BASE)
      .toFixed(18)
    const res = mulpowToFraction(str, tokenOutDecimals) // To cut redundant decimals.
    return res
  }, [fractionalAmountOut, slippageBps, tokenOutDecimals])

  const [isInvert, setIsInvert] = useState(false)

  const fractionalFeeAmount = useMemo(
    () => (tokenIn === MOVE.id ? new Fraction(2, 1000) : new Fraction(0, 1)),
    [tokenIn],
  )
  const isSufficientBalance =
    fractionalBalanceTokenIn && fractionalAmountIn
      ? fractionalBalanceTokenIn.subtract(fractionalFeeAmount).equalTo(fractionalAmountIn) ||
        fractionalBalanceTokenIn.subtract(fractionalFeeAmount).greaterThan(fractionalAmountIn)
      : undefined

  const onSetPercentAmountIn = (percent: number) => {
    if (fractionalBalanceTokenIn && fractionalFeeAmount) {
      let newTypedAmountIn = fractionalBalanceTokenIn.multiply(percent).divide(100)
      if (fractionalBalanceTokenIn.subtract(fractionalFeeAmount).lessThan(newTypedAmountIn)) {
        newTypedAmountIn = newTypedAmountIn.subtract(fractionalFeeAmount)
      }
      if (newTypedAmountIn.greaterThan(0)) {
        const newTypedAmountInStr = newTypedAmountIn.toFixed(18)
        setTypedAmountIn(newTypedAmountInStr, tokenInDecimals, false)
      } else {
        setTypedAmountIn('', tokenInDecimals, false)
      }
    } else {
      setTypedAmountIn('', tokenInDecimals, false)
    }
  }

  const swapButton = useMemo(() => {
    if (isSwapping) return { isDisabled: true, text: 'Swapping...' }
    if (isLoadingNetworkStatus) return { isDisabled: true, text: 'Checking network status...' }
    if (!isNetworkStable) return { isDisabled: true, text: 'Network is not stable now' }
    if (!fractionalAmountIn) return { isDisabled: true, text: 'Enter an amount' }
    if (!isSufficientBalance) return { isDisabled: true, text: 'Insufficient balance' }
    if (isValidatingQuote) return { isDisabled: true, text: 'Getting quote...' }
    if (!fractionalAmountOut) return { isDisabled: true, text: 'Not found route' }
    return { isDisabled: false, text: 'Swap' }
  }, [
    isLoadingNetworkStatus,
    isNetworkStable,
    fractionalAmountIn,
    isSufficientBalance,
    isValidatingQuote,
    fractionalAmountOut,
    isSwapping,
  ])

  const [tokenInLogoSrc, setTokenInLogoSrc] = useState(tokenInInfo?.logoUrl || '/images/404.svg')
  const [tokenOutLogoSrc, setTokenOutLogoSrc] = useState(tokenOutInfo?.logoUrl || '/images/404.svg')
  useEffect(() => {
    setTokenInLogoSrc(tokenInInfo?.logoUrl || '/images/404.svg')
    setTokenOutLogoSrc(tokenOutInfo?.logoUrl || '/images/404.svg')
  }, [tokenInInfo?.logoUrl, tokenOutInfo?.logoUrl])

  const switchToken = useCallback(() => {
    if (fractionalAmountOut && tokenOutDecimals !== undefined) {
      setTypedAmountIn(truncateValue(fractionalAmountOut.toFixed(18), tokenOutDecimals), tokenOutDecimals, false)
    } else {
      setTypedAmountIn('')
    }
    const pair = location.pathname.replace('/swap/', '')
    try {
      const tokenInSymbolOrAddress = pair.split('-')[0]
      const tokenOutSymbolOrAddress = pair.split('-')[1]
      if (!tokenInSymbolOrAddress || !tokenOutSymbolOrAddress) throw new Error(`invalid pair = ${pair}`)
      navigate(`/swap/${tokenOutSymbolOrAddress}-${tokenInSymbolOrAddress}?${params.toString()}`, {
        replace: true,
      })
    } catch (err) {
      pair !== '/swap' && console.error(err)
      navigate(`/swap/MOVE-USDC?${params.toString()}`, { replace: true })
    }
    setTokenInInfo(_tokenOutInfo)
    setTokenOutInfo(_tokenInInfo)
  }, [
    fractionalAmountOut,
    location.pathname,
    _tokenOutInfo,
    _tokenInInfo,
    navigate,
    params,
    setTypedAmountIn,
    tokenOutDecimals,
  ])

  const [activePanel, setActivePanel] = useState(SwapPanel.Swap)
  const setTokenIn = useCallback(
    (token: Asset) => {
      setTokenInInfo(token)
      const symbolOrAddress = token.whitelisted ? token.symbol : token.id
      if (tokenOut === symbolOrAddress || (tokenOutInfo && tokenOutInfo.symbol === symbolOrAddress)) {
        switchToken()
      } else {
        _setTokenIn(symbolOrAddress)
      }
      setActivePanel(SwapPanel.Swap)
    },
    [_setTokenIn, switchToken, tokenOut, tokenOutInfo],
  )
  const setTokenOut = useCallback(
    (token: Asset) => {
      setTokenOutInfo(token)
      const symbolOrAddress = token.whitelisted ? token.symbol : token.id
      if (tokenIn === symbolOrAddress || (tokenInInfo && tokenInInfo.symbol === symbolOrAddress)) {
        switchToken()
      } else {
        _setTokenOut(symbolOrAddress)
      }
      setActivePanel(SwapPanel.Swap)
    },
    [_setTokenOut, switchToken, tokenIn, tokenInInfo],
  )

  const swapCardRef = useRef<HTMLDivElement>(null)

  const onSwap = () => {
    if (fractionalAmountIn && fractionalAmountOut && swapData) {
      void _onSwap({
        tokenIn,
        tokenOut,
        amountIn: fractionalAmountIn.numerator.toString(),
        amountOut: fractionalAmountOut.numerator.toString(),
        swapData,
      })
    }
  }

  useEffect(() => {
    resetTimerFunction.current()
  }, [fractionalAmountIn, tokenIn, tokenOut, isValidatingQuote])

  const [isShowTradeDetails, setShowTradeDetails] = useState(false)

  const controls = useAnimationControls()
  useEffect(() => {
    if (isShowTradeDetails) {
      controls.mount()
      void controls.start({ height: 'fit-content' })
    } else {
      void controls.start({ height: '0' })
    }
  }, [controls, isShowTradeDetails])

  const backToSwap = () => setActivePanel(SwapPanel.Swap)

  return (
    <>
      <div className="z-[1] w-full p-4 pt-[40px]">
        <div className="mx-auto flex max-w-[450px] flex-col" ref={swapCardRef}>
          <div className="flex justify-between">
            {IS_SUPPORT_LO ? <SwapLimitTab /> : <div />}

            <div>
              <Button
                isIconOnly
                className={'h-[32px] w-[32px] min-w-min bg-transparent'}
                disableAnimation
                onPress={async () => {
                  if (!isValidatingQuote) await reFetch()
                }}
              >
                <CountdownSpinner
                  timeInSeconds={10}
                  onFinishCountdown={reFetch}
                  setResetTimerFunc={setResetTimerFunc}
                  isLoading={isValidatingQuote || isSwapping}
                  size={25}
                />
              </Button>
              <Button
                isIconOnly
                className="m-0 h-[32px] w-[32px] min-w-min bg-transparent p-0"
                onPress={() => setActivePanel(SwapPanel.SwapSettings)}
                disableAnimation
              >
                <SettingIcon size={24} color={'#8B8D91'} />
              </Button>
            </div>
          </div>

          <Spacer y={2} />

          {activePanel === SwapPanel.Swap && (
            <SwapForm
              {...{
                connected,
                onSetPercentAmountIn,
                fractionalBalanceTokenIn,
                setTypedAmountIn,
                setActivePanel,
                fractionalAmountInUsd,
                setTokenInLogoSrc,
                typedAmountIn,
                tokenInDecimals,
                tokenInInfo,
                tokenInLogoSrc,
                fractionalBalanceTokenOut,
                switchToken,
                fractionalAmountIn,
                swapButton,
                onOpenModal,
                fractionalAmountOut,
                setShowTradeDetails,
                setTokenOutLogoSrc,
                isValidatingQuote,
                onSwap,
                priceImpact,
                isPriceImpactVeryHigh,
                isInvert,
                setIsInvert,
                tokenOutInfo,
                rate,
                isShowTradeDetails,
                minimumReceived,
                readableAmountOut,
                tokenOutLogoSrc,
                fractionalAmountOutUsd,
                isPriceImpactHigh,
              }}
            />
          )}

          {activePanel === SwapPanel.SelectTokenIn && (
            <TokenSelector
              swapCardRef={swapCardRef}
              onSelectToken={setTokenIn}
              onBack={() => setActivePanel(SwapPanel.Swap)}
            />
          )}

          {activePanel === SwapPanel.SelectTokenOut && (
            <TokenSelector
              swapCardRef={swapCardRef}
              onSelectToken={setTokenOut}
              onBack={() => setActivePanel(SwapPanel.Swap)}
            />
          )}

          {activePanel === SwapPanel.SwapSettings && (
            <SwapSettings
              swapCardRef={swapCardRef}
              onBack={backToSwap}
              openLiquidity={() => setActivePanel(SwapPanel.DexSelector)}
            />
          )}
          {activePanel === SwapPanel.DexSelector && (
            <DexSelector
              swapCardRef={swapCardRef}
              onBack={() => setActivePanel(SwapPanel.SwapSettings)}
              source={source}
              setSource={setSource}
            />
          )}
        </div>
      </div>
      <ModalTradeRoute
        isOpen={globalModal === MODAL_LIST.TRADE_ROUTE && isModalOpen}
        onOpenChange={onOpenChangeModal}
        onClose={onCloseModal}
        srcCoinType={tokenIn}
        dstCoinType={tokenOut}
        readableAmountIn={numberWithCommas(typedAmountIn)}
        readableAmountOut={readableAmountOut}
        rawAmountIn={fractionalAmountIn?.numerator?.toString()}
        paths={paths}
      />
    </>
  )
}
