import useSWR from 'swr'
import { useMemo } from 'react'
import { getAllPools, GetLiqPoolsResponseData } from './useGetLiqPools.ts'

export interface GetUserActivePositionResponseData {
  userAddr: string
  poolAddr: string
  xVal: string
  yVal: string
  lpTokens: string
  createdAt: string
  updatedAt: string
  deletedAt: string
  pool: GetLiqPoolsResponseData
}

export interface GetUserActivePositionResponse {
  data: {
    result: GetUserActivePositionResponseData[]
    pagination: {
      page: number
      size: number
      total: number
    }
  }
}

const fn = async (): Promise<GetUserActivePositionResponse> => {
  // const response = await axios<GetUserActivePositionResponse>(`${AGGREGATOR_URL}/v1/positions`)
  const pools = await getAllPools()
  return {
    data: {
      result: pools.map((e) => ({
        ...e,
        isStable: e.is_stable,
        poolAddr: e.pool_addr,
        tokenX: e.token_x,
        tokenY: e.token_y,
        pool: { ...e, isStable: e.is_stable, tokenY: e.token_y, tokenX: e.token_x, poolAddr: e.pool_addr },
      })),
      pagination: { page: 1, size: 10, total: pools.length },
    },
  } as any
}

export function useGetUserActivePosition() {
  const {
    data: getUserActivePosition,
    error,
    isLoading,
    mutate,
  } = useSWR(
    {
      key: `getUserActivePosition`,
    },
    fn,
  )
  return useMemo(() => {
    return { getUserActivePosition, error, isLoading, reFetch: mutate }
  }, [getUserActivePosition, error, isLoading, mutate])
}
