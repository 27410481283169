import {
  Button,
  Image,
  Pagination,
  Progress,
  Spinner,
  Table,
  TableBody,
  TableCell,
  TableColumn,
  TableHeader,
  TableRow,
} from '@nextui-org/react'
import { ReactElement, useMemo, useState } from 'react'
import { useGetOrdersByUser } from '../../hooks/limit/api/useGetOrdersByUser.ts'
import { numberWithCommas } from '../../utils/number.ts'
import { useCancelOrder } from '../../hooks/contract/useCancelOrder.ts'
import { useGetTokenInfoById } from './OrdersHistory.tsx'
import { usePendingOrders } from '../../redux/hooks/index.ts'
import { GetOrdersResponseData } from '@/interfaces/limit.ts'
import { Asset } from '@/constants/asset.ts'
import { OrderBookConfig, useGetOrderBookConfigs } from '@/hooks/contract/useGetOrderBookConfig.ts'
import { getLimitOrderById } from '@/constants/limitOrder.ts'

export const calcPriceAndSize = ({
  item,
  orderbookConfig,
  baseTokenInfo,
  quoteTokenInfo,
}: {
  item: GetOrdersResponseData
  orderbookConfig: Record<string, OrderBookConfig>
  baseTokenInfo: Asset | undefined
  quoteTokenInfo: Asset | undefined
}) => {
  const orderConfig = orderbookConfig?.[item.orderBookIdx] || getLimitOrderById(item.orderBookIdx)
  if (!baseTokenInfo || !quoteTokenInfo || !orderConfig) return { displayPrice: 0, displaySize: 0, percent: 0 }

  const lotSize = Number(orderConfig.lot_size) / 10 ** baseTokenInfo?.decimals
  const tickSize = Number(orderConfig.tick_size) / 10 ** quoteTokenInfo?.decimals

  const displayPrice = item.isAsk
    ? (Number(item.price) * lotSize) / tickSize
    : tickSize / (Number(item.price) * lotSize)

  const displaySize = item.isAsk ? Number(item.size) * lotSize : Number(item.size) * Number(item.price) * tickSize

  const percent = (Number(item.size) - Number(item.remainingSize)) / Number(item.size)
  return { displayPrice, displaySize, percent }
}

interface RowProps {
  baseTokenInfo: Asset | undefined
  item: GetOrdersResponseData
  quoteTokenInfo: Asset | undefined
  displaySize: number
  displayPrice: number
  percent: number
}

export const TokenAmount = ({ item, baseTokenInfo, quoteTokenInfo, displayPrice, displaySize }: RowProps) =>
  item.isAsk ? (
    <div className="flex flex-col gap-2">
      <div className="flex items-center gap-2">
        <Image src={baseTokenInfo?.logoUrl} width={20} height={20} alt={baseTokenInfo?.symbol} />
        Sell {numberWithCommas(displaySize.toString(), true, 2)} {baseTokenInfo?.symbol}
      </div>
      <div className="flex items-center gap-2">
        <Image src={quoteTokenInfo?.logoUrl} width={20} height={20} alt={quoteTokenInfo?.symbol} />
        Buy {numberWithCommas((displaySize * displayPrice).toString(), true, 3)} {quoteTokenInfo?.symbol}
      </div>
    </div>
  ) : (
    <div className="flex flex-col gap-2">
      <div className="flex items-center gap-2">
        <Image src={quoteTokenInfo?.logoUrl} width={20} height={20} alt={quoteTokenInfo?.symbol} />
        Sell {numberWithCommas(displaySize.toString(), true, 2)} {quoteTokenInfo?.symbol}
      </div>
      <div className="flex items-center gap-2">
        <Image src={baseTokenInfo?.logoUrl} width={20} height={20} alt={baseTokenInfo?.symbol} />
        Buy {numberWithCommas((displaySize * displayPrice).toString(), true, 3)} {baseTokenInfo?.symbol}
      </div>
    </div>
  )

export const OrderRate = ({ item, baseTokenInfo, quoteTokenInfo, displayPrice }: RowProps) =>
  item.isAsk ? (
    <>
      {numberWithCommas(displayPrice.toString(), true, 4)} {quoteTokenInfo?.symbol} per {baseTokenInfo?.symbol}
    </>
  ) : (
    <>
      {numberWithCommas(displayPrice.toString(), true, 4)} {baseTokenInfo?.symbol} per {quoteTokenInfo?.symbol}
    </>
  )

export const FillProgress = ({ displaySize, percent }: RowProps) => (
  <>
    <div className="flex justify-between gap-2">
      {numberWithCommas((percent * displaySize).toString(), true, 2)}/
      {numberWithCommas(displaySize.toString(), true, 2)}
      <div className="font-bold text-primary">({numberWithCommas((percent * 100).toString(), true, 2)}%)</div>
    </div>
    <Progress aria-label="Loading..." value={percent * 100} className="max-w-md" />
  </>
)

export function OpenOrders() {
  const [page, setPage] = useState(1)
  const pageSize = useMemo(() => 5, [])
  const { orders, total, isLoading: isLoadingOrder } = useGetOrdersByUser(['PENDING'], page, pageSize)
  const { orderbookConfig, isLoading: isLoadingConfig } = useGetOrderBookConfigs()

  const isLoading = isLoadingConfig || isLoadingOrder

  const cancelOrder = useCancelOrder()
  const { cancellingOrderIds } = usePendingOrders()

  const classNames = useMemo(
    () => ({
      base: ['gap-0'],
      wrapper: ['bg-dark0', 'p-0', 'gap-0', 'border-x border-t border-ffffff20', 'rounded-none', 'rounded-t-large'],
      th: ['bg-black2', 'text-base', 'text-light2', 'font-semibold', 'px-5 py-2'],
      tr: ['border-b', 'border-ffffff20', 'rounded-none'],
      td: ['px-5 py-2'],
    }),
    [],
  )

  const getTokenInfoById = useGetTokenInfoById()

  const tableRowRender = useMemo(() => {
    if (isLoading) {
      return []
    }

    const rows: ReactElement[] = []
    orders?.forEach((item, index) => {
      const baseTokenInfo = getTokenInfoById(item.baseTokenType)
      const quoteTokenInfo = getTokenInfoById(item.quoteTokenType)
      const { displayPrice, displaySize, percent } = calcPriceAndSize({
        item,
        orderbookConfig,
        baseTokenInfo,
        quoteTokenInfo,
      })
      const cancelling = cancellingOrderIds?.includes(item.id)

      const props = { item, baseTokenInfo, quoteTokenInfo, displayPrice, displaySize, percent }
      const row = (
        <TableRow key={index} className="cursor-pointer text-white">
          <TableCell>
            <TokenAmount {...props} />
          </TableCell>
          <TableCell>
            <OrderRate {...props} />
          </TableCell>
          <TableCell>
            <FillProgress {...props} />
          </TableCell>
          <TableCell className="text-right">
            <Button
              isDisabled={cancelling}
              className="border border-red-500 bg-[#EB575726]"
              onClick={async () => {
                await cancelOrder({
                  id: item.id,
                  baseToken: item.baseTokenType,
                  quoteToken: item.quoteTokenType,
                })
              }}
            >
              {cancelling ? 'Canceling' : 'Cancel'}
            </Button>
          </TableCell>
        </TableRow>
      )
      rows.push(row)
    })
    return rows
  }, [isLoading, orders, cancellingOrderIds, cancelOrder, orderbookConfig, getTokenInfoById])

  return (
    <>
      <Table
        aria-label="open-order-table"
        classNames={classNames}
        bottomContent={
          !isLoading && (
            <div className="  flex items-center justify-center rounded-b-large border-x border-b px-2 py-2">
              <Pagination
                showControls
                classNames={{
                  cursor: ['cursor-pointer text-dark0'],
                }}
                onChange={(page) => {
                  setPage(page)
                }}
                page={page}
                total={Math.ceil((total || 1) / pageSize)}
                variant="light"
              />
            </div>
          )
        }
        bottomContentPlacement={'outside'}
      >
        <TableHeader className="">
          <TableColumn className="min-w-48 text-white">Order(s)</TableColumn>
          <TableColumn className="min-w-48 text-white">Rate</TableColumn>
          <TableColumn className="text-white">Total Filled</TableColumn>
          <TableColumn className="text-right text-white">Action</TableColumn>
        </TableHeader>
        <TableBody
          emptyContent={'No open orders'}
          isLoading={isLoading}
          loadingContent={<Spinner label="Loading..." />}
        >
          {tableRowRender.map((row) => row)}
        </TableBody>
      </Table>
    </>
  )
}
