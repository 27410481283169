import { Button, Input, Modal, ModalContent, Spacer } from '@nextui-org/react'
import { CSSProperties, memo, useCallback, useMemo, useState } from 'react'
import { FixedSizeList } from 'react-window'
import { useDebounceValue, useWindowSize } from 'usehooks-ts'
import { useIsSm } from '../../hooks/useMedia.ts'
import { useAppDispatch, useAppSelector } from '../../redux/hooks'
import { addTokensToFollow } from '../../redux/slices/token'
import { Fraction } from '../../utils/fraction'
import BasicTokenInfo from '../BasicTokenInfo.tsx'
import { CloseIcon, SearchIcon } from '../Icons'
import { useAssetsList } from '../../hooks/useAssetsList.ts'
import { Asset, MOVE, USDC } from '../../constants/asset.ts'

export interface TokenWithBalance extends Asset {
  isFollowing: boolean
  fractionalBalance?: Fraction
  fractionalBalanceUsd?: Fraction
}

function TokenItem({
  index,
  data: { items, setToken },
  style,
}: {
  index: number
  data: {
    items: TokenWithBalance[]
    setToken: (id: string) => void
  }
  style: CSSProperties
}) {
  const token = useMemo(() => {
    return items[index]!
  }, [items, index])

  return (
    <div
      className={
        'bg-buttonDisabled flex h-fit w-full cursor-pointer items-center gap-2 rounded-none px-4 py-3 font-normal hover:bg-background' +
        ' ' +
        (token.isFollowing ? 'opacity-100' : 'opacity-20')
      }
      tabIndex={0}
      style={style}
      onClick={() => setToken(token.whitelisted ? token.symbol : token.id)}
    >
      <BasicTokenInfo token={token} />
    </div>
  )
}

function ModalSelectToken({
  isOpen,
  onClose: _onClose,
  onOpenChange: _onOpenChange,
  setToken,
}: {
  isOpen: boolean
  onClose: () => void
  onOpenChange: () => void
  setToken: (id: string) => void
}) {
  const dispatch = useAppDispatch()
  const { assetTokenList } = useAssetsList(true)
  const followingTokenData = useAppSelector((state) => state.token.followingTokenData)

  const getTokenInfo = useCallback(
    (tokenSymbolOrAddress: string): Asset | undefined => {
      return Object.values(followingTokenData).find((token) => {
        try {
          return token?.symbol === tokenSymbolOrAddress || token?.id === tokenSymbolOrAddress
        } catch {
          return false
        }
      })
    },
    [followingTokenData],
  )

  const supportToken = useMemo(() => {
    return [getTokenInfo(MOVE.id), getTokenInfo(USDC.id)]
  }, [getTokenInfo])

  const renderTokenList = useMemo(() => {
    return assetTokenList.filter((token) => {
      return supportToken.find((support) => support?.id === token?.id)
    })
  }, [assetTokenList, supportToken])

  const onClose = useCallback(() => {
    setSearchValue('')
    _onClose()
  }, [_onClose])

  const onOpenChange = useCallback(() => {
    setSearchValue('')
    _onOpenChange()
  }, [_onOpenChange])

  const setTokenAndClose = useCallback(
    (id: string) => {
      dispatch(addTokensToFollow([id]))
      setToken(id)
      onClose()
    },
    [dispatch, onClose, setToken],
  )

  const [_searchValue, setSearchValue] = useState('')
  const [searchValue] = useDebounceValue(_searchValue, 250)

  const isEmpty = renderTokenList.length === 0

  const itemData = useMemo(
    () => ({ items: renderTokenList, setToken: setTokenAndClose }),
    [renderTokenList, setTokenAndClose],
  )

  const { height: windowHeight } = useWindowSize()
  const listHeight = useMemo(() => Math.min(680, Math.round(windowHeight / 2 / 68) * 68), [windowHeight])

  const isSm = useIsSm()

  return (
    <>
      <Modal
        isOpen={isOpen}
        onOpenChange={onOpenChange}
        placement="center"
        backdrop="blur"
        hideCloseButton
        disableAnimation
        size={isSm ? 'full' : undefined}
      >
        <ModalContent
          className={
            'bg-dark0 max-w-[420px] p-4 pb-0 text-foreground dark' + ' ' + (isSm ? 'h-fit max-h-[70vh] self-end' : '')
          }
        >
          <>
            <div className="flex items-center justify-between">
              <div>Select a token</div>
              <Button isIconOnly variant="light" className="h-[20px] w-[20px] min-w-fit p-0" onPress={onClose}>
                <CloseIcon size={20} />
              </Button>
            </div>

            <Spacer y={4} />

            <Input
              type="text"
              placeholder="Search by token symbol or address"
              labelPlacement="outside"
              autoComplete="off"
              autoCorrect="off"
              spellCheck={false}
              className="input-modal-select-token"
              startContent={<SearchIcon size={20} />}
              endContent={
                searchValue ? (
                  <Button
                    isIconOnly
                    className="m-0 h-fit w-fit min-w-fit border-transparent bg-transparent p-0"
                    disableRipple
                    onPress={() => setSearchValue('')}
                  >
                    <CloseIcon size={16} />
                  </Button>
                ) : null
              }
              value={_searchValue}
              onChange={(e) => setSearchValue(e.currentTarget.value)}
            />

            <Spacer y={4} />

            {renderTokenList && (
              <div className="relative -mx-4 border-t-1 border-t-background">
                <FixedSizeList
                  height={listHeight}
                  itemCount={renderTokenList.length}
                  itemSize={68}
                  width="100%"
                  itemData={itemData}
                >
                  {TokenItem}
                </FixedSizeList>
                {isEmpty && (
                  <div className="absolute left-1/2 top-1/4 -translate-x-1/2 -translate-y-1/2">No Token Found</div>
                )}
              </div>
            )}
          </>
        </ModalContent>
      </Modal>
    </>
  )
}

const MemorizedModalSelectToken = memo(ModalSelectToken)
export default MemorizedModalSelectToken
