import { useCallback, useMemo, useState } from 'react'
import { escapeRegExp, inputRegex, mulpowToFraction, numberWithCommas, truncateValue } from '../../../utils/number.ts'

export function useSetTypedAmount(defaultValue: string, tokenInDecimals?: number) {
  const [typedAmount, _setTypedAmount] = useState(defaultValue)

  const setTypedAmount = useCallback((value: string, decimals = 8) => {
    if (value?.endsWith(',')) {
      value = value.slice(0, value.length - 1) + '.'
    }
    value = value.replaceAll(',', '')
    if (value === '' || inputRegex.test(escapeRegExp(value))) {
      value = truncateValue(value, decimals)
      if (value.length && value.startsWith('.')) value = '0.'
      value = numberWithCommas(value)
      _setTypedAmount(value)
    }
  }, [])

  const fractionalAmount = useMemo(
    () =>
      typedAmount && tokenInDecimals !== undefined
        ? mulpowToFraction(typedAmount.replaceAll(',', ''), tokenInDecimals)
        : undefined,
    [tokenInDecimals, typedAmount]
  )
  return useMemo(
    () => ({ typedAmount, setTypedAmount, fractionalAmount }),
    [typedAmount, setTypedAmount, fractionalAmount]
  )
}