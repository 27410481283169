import { useCallback, useMemo, useState } from 'react'
import { useAptosWallet } from '@razorlabs/wallet-kit'
import { AMM_CONTRACT, aptos } from '../../../constants'
import { Ed25519PublicKey, isUserTransactionResponse } from '@aptos-labs/ts-sdk'
import invariant from 'invariant'
import useAddLiqNotificationFn from '../../notifications/useAddLiqNotificationFn.tsx'
import useRefreshBalanceFn from '../../useRefreshBalanceFn.ts'
import { sleep } from '../../../utils/time.ts'

export function useAddLiq() {
  const [isAddingLiq, setIsAddingLiq] = useState(false)
  const { account, signAndSubmitTransaction } = useAptosWallet()

  const sendNotification = useAddLiqNotificationFn()
  const refreshBalance = useRefreshBalanceFn()

  const addLiq = useCallback(
    async (args: {
      pool: string
      amountXVal: string
      amountXValMin: string
      amountYVal: string
      amountYValMin: string
      tokenX: string
      tokenY: string
    }) => {
      setIsAddingLiq(true)
      try {
        if (!account) return
        const payload = {
          function: `${AMM_CONTRACT}::scripts::add_liquidity_both_coins` as `${string}::${string}::${string}`,
          functionArguments: [args.pool, args.amountXVal, args.amountXValMin, args.amountYVal, args.amountYValMin],
          typeArguments: [args.tokenX, args.tokenY],
        }
        const transaction = await aptos.transaction.build.simple({
          sender: account.address,
          data: payload,
        })

        const simulateResponses = await aptos.transaction.simulate.simple({
          signerPublicKey: new Ed25519PublicKey(account.publicKey),
          transaction,
          options: { estimateMaxGasAmount: true },
        })

        invariant(simulateResponses.length === 1, 'simulateResponse length should be 1')
        const simulateResponse = simulateResponses[0]
        invariant(simulateResponse.success, simulateResponse.vm_status)

        const submitResponse = await signAndSubmitTransaction({ payload })

        // eslint-disable-next-line @typescript-eslint/no-unsafe-enum-comparison
        invariant(submitResponse.status === 'Approved', 'submitResponse.status REJECTED')
        const hash = submitResponse.args.hash

        const receipt = await aptos.waitForTransaction({
          transactionHash: hash,
          options: { checkSuccess: false, waitForIndexer: false, timeoutSecs: 5000 },
        })
        invariant(isUserTransactionResponse(receipt), 'isUserTransactionResponse(receipt) failed')
        invariant(receipt.success, receipt.vm_status)

        sendNotification({
          tokenX: args.tokenX,
          tokenY: args.tokenY,
          amountX: args.amountXVal,
          amountY: args.amountYVal,
          txHash: hash,
          isSuccess: true,
          details: '',
          isRemove: false,
        })
      } catch (err) {
        console.log('err', err)
        const jsonErr = JSON.stringify(err)
        if (jsonErr !== `{"code":"WALLET.SIGN_TX_ERROR"}`) {
          sendNotification({
            tokenX: args.tokenX,
            tokenY: args.tokenY,
            amountX: args.amountXVal,
            amountY: args.amountYVal,
            isSuccess: false,
            details: err,
            isRemove: false,
          })
        }
      } finally {
        setIsAddingLiq(false)
        await sleep(4000)
        void refreshBalance()
      }
    },
    [account, refreshBalance, sendNotification, signAndSubmitTransaction],
  )

  return useMemo(() => ({ isAddingLiq, addLiq }), [isAddingLiq, addLiq])
}
