import { useMemo } from 'react'
import { MOVE } from '../../constants/asset.ts'
import { Fraction } from '../../utils/fraction.ts'

export function useFeeAmount(tokenAddress: string) {
  const fractionalFeeAmount = useMemo(
    () => (tokenAddress === MOVE.id ? new Fraction(2, 1000) : new Fraction(0, 1)),
    [tokenAddress]
  )

  return useMemo(() => fractionalFeeAmount, [fractionalFeeAmount])
}