import useSWR from 'swr'
import { AMM_CONTRACT, aptos } from '../../../constants'
import { useMemo } from 'react'

const fn = async ({ pool, lpTokenVal }: { pool: string; lpTokenVal: string }) => {
  if (!lpTokenVal) {
    return {
      xToReturnVal: '0',
      yToReturnVal: '0'
    }
  }
  const result: string[] = await aptos.viewJson({
    payload: {
      function: `${AMM_CONTRACT}::router::quote_remove_liquidity`,
      functionArguments: [pool, lpTokenVal]
    }
  })
  return {
    xToReturnVal: result[0],
    yToReturnVal: result[1]
  }
}

export function useQuoteRemoveLiq(pool: string, lpTokenVal: string) {
  const { data: quoteRemoveLiq, error, isValidating, mutate } = useSWR(
    {
      key: `quoteRemoveLiq-${pool}-${lpTokenVal}`,
      pool,
      lpTokenVal,
    },
    fn
  )
  return useMemo(() => {
    return { quoteRemoveLiq, error, isValidating, reFetch: mutate }
  }, [quoteRemoveLiq, error, isValidating, mutate])
}