import { useCallback, useEffect, useMemo } from 'react'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { addTokensToFollow } from '../../../redux/slices/token.ts'
import useFullTokens from '../../useFullTokens.ts'
import { useAppDispatch, useAppSelector } from '../../../redux/hooks'
import { Asset, MOVE, USDC } from '../../../constants/asset.ts'

export function useParseTokenInOut(path: string) {
  const navigate = useNavigate()
  const location = useLocation()
  const [params] = useSearchParams()
  const dispatch = useAppDispatch()
  const { data: fullTokenData } = useFullTokens()
  const followingTokenData = useAppSelector((state) => state.token.followingTokenData)

  const token0SymbolOrAddress = useMemo(() => {
    try {
      return location.pathname.replace(`/${path}/`, '').split('-')[0]
    } catch {
      navigate(`/${path}/MOVE-USDC?${params.toString()}`, { replace: true })
    }
  }, [location.pathname, navigate, params, path])

  const token1SymbolOrAddress = useMemo(() => {
    try {
      return location.pathname.replace(`/${path}/`, '').split('-')[1]
    } catch {
      navigate(`/${path}/MOVE-USDC?${params.toString()}`, { replace: true })
    }
  }, [location.pathname, navigate, params, path])

  useEffect(() => {
    const pair = location.pathname.replace(`/${path}/`, '')
    try {
      const token0SymbolOrAddress = pair.split('-')[0]
      const token1SymbolOrAddress = pair.split('-')[1]
      if (!token0SymbolOrAddress || !token1SymbolOrAddress) throw new Error(`invalid pair = ${pair}`)

      const followingTokenDataList = Object.values(followingTokenData) as Asset[]
      if (!fullTokenData || Object.values(fullTokenData).length === 0) return

      const fullTokenDataList = Object.values(fullTokenData) as Asset[]
      const newToken0 =
        fullTokenDataList.find((token) => token.id === token0SymbolOrAddress) ||
        followingTokenDataList.find((token) => token.symbol === token0SymbolOrAddress)
      const newToken1 =
        fullTokenDataList.find((token) => token.id === token1SymbolOrAddress) ||
        followingTokenDataList.find((token) => token.symbol === token1SymbolOrAddress)

      if (!newToken0) throw new Error(`cannot find tokenIn = ${token0SymbolOrAddress}`)
      if (!newToken1) throw new Error(`cannot find tokenOut = ${token1SymbolOrAddress}`)
      dispatch(addTokensToFollow([newToken0.id, newToken1.id]))
    } catch (err) {
      pair !== `/${path}` && console.error(err)
      navigate(`/${path}/MOVE-USDC?${params.toString()}`, { replace: true })
    }
  }, [dispatch, followingTokenData, fullTokenData, location.pathname, navigate, params, path])

  const redirectPair = useCallback(
    (token0SymbolOrAddress: string, token1SymbolOrAddress: string) => {
      navigate(`/${path}/${token0SymbolOrAddress}-${token1SymbolOrAddress}?${params.toString()}`, { replace: true })
    },
    [navigate, params, path],
  )

  return useMemo(
    () => ({
      token0SymbolOrAddress: token0SymbolOrAddress || MOVE.symbol,
      token1SymbolOrAddress: token1SymbolOrAddress || USDC.symbol,
      redirectPair,
    }),
    [redirectPair, token0SymbolOrAddress, token1SymbolOrAddress],
  )
}
