import { Icon } from '@iconify/react'
import { Button } from '@nextui-org/react'
import { useAppDispatch, useAppSelector } from '../../redux/hooks'
import { setDirtySlippage, setSlippageBps } from '../../redux/slices/user.ts'
import { NumericFormat } from 'react-number-format'
import { Body2, Body3, Subtitle1 } from '../../components/Typography.tsx'
import { mulpowToFraction } from '../../utils/number.ts'
import { ReactNode, RefObject, useState } from 'react'
import { useOnClickOutside } from 'usehooks-ts'
import MainMotion from '../../components/anim/MainMotion.tsx'
import { useLiquiditySource } from '../../redux/slices/hooks.ts'
import { ALL_DEXES } from '../../constants/pool.ts'

function ButtonSlippage({
                          value,
                          shouldHighlight,
                          callback
                        }: {
  value: number
  shouldHighlight: boolean
  callback: () => void
}) {
  const dispatch = useAppDispatch()
  const onPress = () => {
    dispatch(setSlippageBps(value))
    callback()
  }

  return (
    <Button
      className={
        'h-[32px] w-[75px] min-w-[75px] rounded-lg bg-transparent py-2 sm:min-w-[60px] sm:p-[10px]' +
        ' ' +
        (shouldHighlight ? '!bg-primary' : '')
      }
      disableAnimation
      onPress={onPress}
    >
      <Body2 className={'text-baseGrey' + ' ' + (shouldHighlight ? '!font-semibold !text-baseBlack' : '')}>
        {value / 100}%
      </Body2>
    </Button>
  )
}

export const SettingHeader = ({ onBack, title, action }: { onBack: () => void; title: string; action?: ReactNode }) => {
  return (
    <div className="flex items-center justify-between">
      <Icon className="cursor-pointer" onClick={onBack} icon="mdi:chevron-left" color="#8B8D91" fontSize={26} />
      <Subtitle1 className="text-baseGrey">{title}</Subtitle1>
      {action || <Icon className="invisible" icon="mdi:chevron-left" color="#8B8D91" fontSize={26} />}
    </div>
  )
}

export default function SwapSettings({
  onBack: _onBack,
  swapCardRef,
  openLiquidity,
}: {
  onBack: () => void
  openLiquidity: () => void
  swapCardRef: RefObject<HTMLDivElement>
}) {
  const dispatch = useAppDispatch()
  const slippageBps = useAppSelector((state) => state.user.slippageBps)
  const dirtySlippage = useAppSelector((state) => state.user.dirtySlippage)
  const [isInputFocus, setIsInputFocus] = useState(false)

  const onPressButtonSetSlippageBps = (newSlippageBps: number) => {
    dispatch(setSlippageBps(newSlippageBps))
    setIsInputFocus(false)
  }

  const onInputFocus = () => {
    setIsInputFocus(true)
  }

  const isHighlightInput = ![5, 10, 50, 100].includes(slippageBps) || isInputFocus

  const swapSettingCallback = () => {
    if (!isInputFocus) {
      _onBack()
      return
    }

    const dirtySlippageBps = mulpowToFraction(dirtySlippage, 2)
    if (dirtySlippageBps.greaterThan(0) && (dirtySlippageBps.lessThan(100) || dirtySlippageBps.equalTo(100))) {
      dispatch(setSlippageBps(Number(dirtySlippageBps.numerator.toString())))
      _onBack()
    } else {
      const ele = document.getElementById('maybe-shake')
      ele?.className && (ele.className = ele.className + ' shake')
      setTimeout(() => ele?.className && (ele.className = ele.className.replaceAll('shake', '').trim()), 250)
    }
  }
  useOnClickOutside(swapCardRef, swapSettingCallback)
  const { totalSource } = useLiquiditySource()

  return (
    <MainMotion>
      <div className="flex flex-col gap-4 rounded-lg border-[0.5px] border-borderGrey2 bg-baseGrey1 p-3">
        <SettingHeader onBack={swapSettingCallback} title="Swap settings" />

        <div className="flex flex-col gap-3">
          <div className="flex items-center gap-1">
            <Body3 className="w-fit text-white">Max slippage</Body3>
          </div>

          <div className="flex h-[44px] items-center overflow-hidden rounded-lg border-[0.5px] border-borderGrey2 bg-basicBg px-1.5">
            <ButtonSlippage
              value={5}
              shouldHighlight={!isHighlightInput && slippageBps === 5}
              callback={() => onPressButtonSetSlippageBps(5)}
            />
            <ButtonSlippage
              value={10}
              shouldHighlight={!isHighlightInput && slippageBps === 10}
              callback={() => onPressButtonSetSlippageBps(10)}
            />
            <ButtonSlippage
              value={50}
              shouldHighlight={!isHighlightInput && slippageBps === 50}
              callback={() => onPressButtonSetSlippageBps(50)}
            />
            <ButtonSlippage
              value={100}
              shouldHighlight={!isHighlightInput && slippageBps === 100}
              callback={() => onPressButtonSetSlippageBps(100)}
            />
            <div
              id="maybe-shake"
              className={
                'flex h-[32px] items-center gap-1 rounded-lg pr-3' +
                ' ' +
                (isHighlightInput ? 'bg-primary text-baseBlack' : 'bg-basicBg text-baseGrey')
              }
            >
              <NumericFormat
                decimalSeparator="."
                allowedDecimalSeparators={[',']}
                className={
                  'w-full bg-transparent text-end text-[16px] font-normal outline-none sm:text-[14px]' +
                  ' ' +
                  (isHighlightInput
                    ? 'font-semibold text-baseBlack placeholder:text-baseBlack'
                    : 'text-baseGrey placeholder:text-baseGrey')
                }
                inputMode="decimal"
                autoComplete="off"
                autoCorrect="off"
                type="text"
                minLength={1}
                maxLength={5}
                min={0.01}
                max={100}
                allowNegative={false}
                decimalScale={2}
                spellCheck="false"
                pattern="^[0-9]{1,2}[.,]?[0-9]{0,2}$"
                value={dirtySlippage}
                onChange={(e) => dispatch(setDirtySlippage(e.currentTarget.value))}
                onFocus={onInputFocus}
                isAllowed={(values) => {
                  const { floatValue, value } = values
                  return value === '' || Boolean(floatValue && floatValue >= 0.01 && floatValue <= 100)
                }}
              />
              <Body2 className="text-inherit">%</Body2>
            </div>
          </div>

          <div className="flex items-center justify-between gap-1" onClick={openLiquidity}>
            <Body3 className="w-fit text-white">Liquidity Sources</Body3>
            <Button
              variant="light"
              className="m-0 h-fit w-fit min-w-fit gap-0 p-0 data-[hover]:bg-transparent"
              disableAnimation
              disableRipple
              onPress={openLiquidity}
            >
              <Body3>{totalSource > 0 ? `${totalSource}/${ALL_DEXES.length}` : ALL_DEXES.length}</Body3>
              <Icon icon="mdi:chevron-right" color="#8B8D91" fontSize={26} />
            </Button>
          </div>
        </div>
      </div>
    </MainMotion>
  )
}
