import { Link } from '@nextui-org/react'
import { useCallback } from 'react'
import { isDesktop } from 'react-device-detect'
import { toast } from 'react-toastify'

export default function useLimitOrderNotificationFn() {
  return useCallback(
    (message: string | undefined, txHash: string | undefined, isSuccess: boolean, details: string | undefined) => {
      toast(
        isSuccess ? (
          <div className="rounded bg-[rgba(24,207,106,0.2)] p-4">
            <div>{message} successfully</div>
            {txHash && (
              <Link
                href={`https://explorer.movementnetwork.xyz/txn/${txHash}?network=testnet`}
                isExternal
                showAnchorIcon
                className=""
              >
                <div>View on explorer</div>
              </Link>
            )}
          </div>
        ) : (
          <div className="rounded bg-[rgba(244,70,70,0.2)] p-4">
            <div>{message} failed</div>
            {txHash && (
              <Link
                href={`https://explorer.movementnetwork.xyz/txn/${txHash}?network=testnet`}
                isExternal
                showAnchorIcon
                className=""
              >
                <div>View on explorer</div>
              </Link>
            )}
            {details && <div>{details}</div>}
          </div>
        ),
        {
          className: 'z-toast',
          bodyClassName: 'z-toast-body',
          progressClassName: isSuccess ? 'z-toast-progress-success' : 'z-toast-progress-failed',
          autoClose: isSuccess || isDesktop ? 4000 : false,
          pauseOnHover: isDesktop,
          position: 'top-right',
        },
      )
    },
    [],
  )
}
