import useSWR from 'swr'
import { AMM_CONTRACT, aptos } from '../../../constants'
import { useMemo } from 'react'

const fn = async ({ pool, amount, isXIn }: { pool: string; amount: string | undefined; isXIn: boolean }) => {
  if (!amount) {
    return {
      otherTokenAmount: '0',
      lpAmount: '0'
    }
  }
  const result: string[] = await aptos.viewJson({
    payload: {
      function: `${AMM_CONTRACT}::router::quote_add_liquidity_one_side`,
      functionArguments: [pool, amount, isXIn]
    }
  })
  return {
    otherTokenAmount: result[0],
    lpAmount: result[1]
  }
}

export function useQuoteAddLiq(pool: string, amount: string | undefined, isXIn: boolean) {
  const { data: quoteAddLiq, error, isValidating, mutate } = useSWR(
    {
      key: `quoteAddLiq-${pool}-${amount}-${isXIn}`,
      pool,
      amount,
      isXIn
    },
    fn
  )
  return useMemo(() => {
    return { quoteAddLiq, error, isValidating, reFetch: mutate }
  }, [quoteAddLiq, error, isValidating, mutate])
}