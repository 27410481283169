import { Button } from '@nextui-org/react'
import { useState } from 'react'
import { OrdersHistory } from './OrdersHistory.tsx'
import { OpenOrders } from './OpenOrders.tsx'

export function LimitOrders() {
  const [tab, setTab] = useState<string>('open-orders')

  return (
    <div className="flex flex-col gap-3">
      <div className="flex gap-2">
        <Button
          className={`h-[42px] rounded-lg ${tab === 'open-orders' ? 'bg-primary' : 'bg-black'}`}
          onClick={() => {
            setTab('open-orders')
          }}
        >
          <div className={tab === 'open-orders' ? '' : ''}>Open Orders</div>
        </Button>
        <Button
          className={`h-[42px] rounded-lg ${tab === 'orders-history' ? 'bg-primary' : 'bg-black'}`}
          onClick={() => {
            setTab('orders-history')
          }}
        >
          <div className={tab === 'orders-history' ? '' : ''}>Orders History</div>
        </Button>
      </div>

      {tab === 'open-orders' ? <OpenOrders /> : <OrdersHistory />}
    </div>
  )
}
