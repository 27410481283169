import { useMemo } from 'react'
import { mulpowToFraction, numberWithCommas, truncateValue } from '../../utils/number.ts'
import { Fraction } from '../../utils/fraction.ts'
import { useTokenInOutInfo } from './useTokenInOutInfo.ts'
import { useTokenInOutPrice } from './useTokenInOutPrice.ts'
import { useGetOrderBookConfig } from '../contract/useGetOrderBookConfig.ts'
import { MOVE } from '../../constants/asset.ts'

export function useAmount(
  tokenInSymbolOrAddress: string,
  tokenOutSymbolOrAddress: string,
  fractionalAmountIn?: Fraction,
  fractionalTypedTargetPrice?: Fraction,
) {
  const { tokenIn, tokenInInfo, tokenOutInfo, tokenInDecimals, tokenOutDecimals } = useTokenInOutInfo(
    tokenInSymbolOrAddress,
    tokenOutSymbolOrAddress,
  )

  const { orderBookConfig } = useGetOrderBookConfig(tokenInInfo?.coinType ?? '', tokenOutInfo?.coinType ?? '')

  const { fractionalPriceTokenIn, fractionalPriceTokenOut } = useTokenInOutPrice(
    tokenInSymbolOrAddress,
    tokenOutSymbolOrAddress,
  )

  const fractionalAmountOut = useMemo(() => {
    if (fractionalAmountIn && fractionalTypedTargetPrice) {
      return fractionalTypedTargetPrice.multiply(fractionalAmountIn)
    }
  }, [fractionalAmountIn, fractionalTypedTargetPrice])

  const readableAmountOut =
    fractionalAmountOut && tokenOutDecimals !== undefined
      ? numberWithCommas(truncateValue(fractionalAmountOut.toFixed(18), tokenOutDecimals))
      : ''

  const fractionalAmountOutUsd = useMemo(
    () =>
      fractionalAmountOut && fractionalPriceTokenOut
        ? fractionalAmountOut.multiply(fractionalPriceTokenOut)
        : undefined,
    [fractionalAmountOut, fractionalPriceTokenOut],
  )

  const fractionalAmountInUsd = useMemo(
    () =>
      fractionalAmountIn && fractionalPriceTokenIn ? fractionalAmountIn.multiply(fractionalPriceTokenIn) : undefined,
    [fractionalAmountIn, fractionalPriceTokenIn],
  )

  const fractionalFeeAmount = useMemo(
    () => (tokenIn === MOVE.id ? new Fraction(2, 1000) : new Fraction(0, 1)),
    [tokenIn],
  )

  const isAsk = useMemo(() => {
    return orderBookConfig?.base_type_name === tokenInInfo?.coinType
  }, [orderBookConfig, tokenInInfo])

  const lotRatio = useMemo(() => {
    if (orderBookConfig) {
      if (isAsk) {
        const base = mulpowToFraction('1', tokenInDecimals)
        return base.divide(new Fraction(orderBookConfig.lot_size, 1))
      } else {
        const base = mulpowToFraction('1', tokenOutDecimals)
        return base.divide(new Fraction(orderBookConfig.lot_size, 1))
      }
    }
  }, [isAsk, orderBookConfig, tokenInDecimals, tokenOutDecimals])

  const tickRatio = useMemo(() => {
    if (orderBookConfig) {
      if (isAsk) {
        const base = mulpowToFraction('1', tokenOutDecimals)
        return base.divide(new Fraction(orderBookConfig.tick_size, 1))
      } else {
        const base = mulpowToFraction('1', tokenInDecimals)
        return base.divide(new Fraction(orderBookConfig.tick_size, 1))
      }
    }
  }, [isAsk, orderBookConfig, tokenInDecimals, tokenOutDecimals])

  const contractParamPrice = useMemo(() => {
    if (tickRatio && lotRatio && fractionalTypedTargetPrice?.greaterThan(new Fraction(0))) {
      if (isAsk) {
        return fractionalTypedTargetPrice.multiply(tickRatio).divide(lotRatio)
      } else {
        return tickRatio.divide(lotRatio.multiply(fractionalTypedTargetPrice))
      }
    }
  }, [tickRatio, lotRatio, isAsk, fractionalTypedTargetPrice])

  const contractParamSize = useMemo(() => {
    if (
      orderBookConfig &&
      fractionalAmountIn &&
      tokenInDecimals &&
      tokenOutDecimals &&
      contractParamPrice?.greaterThan(new Fraction(0))
    ) {
      if (isAsk) {
        return fractionalAmountIn
          .multiply(new Fraction(10 ** tokenInDecimals, 1))
          .divide(new Fraction(orderBookConfig.lot_size, 1))
      } else {
        return fractionalAmountIn
          .multiply(new Fraction(10 ** tokenInDecimals, 1))
          .divide(new Fraction(orderBookConfig.tick_size, 1)) // tokenInDecimals
          .divide(contractParamPrice)
      }
    }
  }, [orderBookConfig, fractionalAmountIn, tokenInDecimals, tokenOutDecimals, contractParamPrice, isAsk])

  return useMemo(
    () => ({
      fractionalAmountOut,
      fractionalAmountInUsd,
      fractionalAmountOutUsd,
      fractionalFeeAmount,
      readableAmountOut,
      contractParamPrice,
      contractParamSize,
      isAsk,
    }),
    [
      fractionalAmountOut,
      fractionalAmountInUsd,
      fractionalAmountOutUsd,
      fractionalFeeAmount,
      readableAmountOut,
      contractParamPrice,
      contractParamSize,
      isAsk,
    ],
  )
}

// base token is MOVE, quote token is USDC
// lot size = A, tick size = B
// case 1: isAsk = true
// suppose
// 1 MOVE             = priceMOVEonUSDC x 1 USDC
// A x lotRatio MOVE  = priceMOVEonUSDC x B x tickRatio USDC
// A MOVE             = priceMOVEonUSDC x tickRatio/lotRatio x B USDC
// A MOVE             = contractParamPrice x B USDC
// contractParamPrice = (priceMOVEonUSDC x tickRatio) /lotRatio

// case 2: isAsk = false
// suppose
// 1 MOVE             = priceUSDConMOVE x 1 USDC
// 1 MOVE             = 1/priceUSDConMOVE x 1 USDC
// A x lotRatio MOVE  = 1/priceUSDConMOVE x B x tickRatio USDC
// A MOVE             = priceUSDConMOVE x tickRatio/lotRatio x B USDC
// A MOVE             = contractParamPrice x B USDC
// contractParamPrice = 1/priceUSDConMOVE x tickRatio/lotRatio
// contractParamPrice = tickRatio/(lotRatio x priceUSDConMOVE)

// is_ask = true, user sell base token, contract takes size * lot_size base token from user
// is_ask = false, user sell quote token, contract takes size * price * tick_size quote token from user

// deposit P MOVE
// -> deposit size * lot_size MOVE -> size = P/lot_size
// deposit Q USDC
// -> deposit size * price * tick_size USDC -> size = Q/(price * tick_size)
