import { useDispatch, useSelector } from 'react-redux'
import type { RootState, AppDispatch } from '../store'
import { appSlice } from '../slices/app'
import { useCallback } from 'react'

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = useDispatch.withTypes<AppDispatch>()
export const useAppSelector = useSelector.withTypes<RootState>()

export const usePendingOrders = () => {
  const dispatch = useAppDispatch()
  const { cancellingOrderIds } = useAppSelector((state) => state.app)
  const addPendingOrders = useCallback(
    (key: string) => {
      dispatch(appSlice.actions.updatePendingOrders({ id: key, isRemove: false }))
    },
    [dispatch],
  )

  const removePendingOrders = useCallback(
    (key: string) => {
      dispatch(appSlice.actions.updatePendingOrders({ id: key, isRemove: true }))
    },
    [dispatch],
  )

  return { addPendingOrders, removePendingOrders, cancellingOrderIds }
}
