import { useMemo } from 'react'
import { Fraction } from '../../../utils/fraction.ts'

export function useIsSufficientBalance(fractionalBalance: Fraction | undefined, fractionalAmountIn: Fraction | undefined, fractionalFeeAmount: Fraction | undefined) {
  return useMemo(() => {
    return fractionalBalance && fractionalAmountIn && fractionalFeeAmount
      ? fractionalBalance.subtract(fractionalFeeAmount).equalTo(fractionalAmountIn) ||
      fractionalBalance.subtract(fractionalFeeAmount).greaterThan(fractionalAmountIn)
      : undefined
  }, [fractionalBalance, fractionalAmountIn, fractionalFeeAmount])
}