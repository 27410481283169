import { useMemo, useRef } from 'react'

export function useResetTimerFn() {
  const resetTimerFunction = useRef(() => {
  })
  const setResetTimerFunc = (f: () => void) => (resetTimerFunction.current = f)

  return useMemo(() => ({
    resetTimerFn: resetTimerFunction.current,
    setResetTimerFn: setResetTimerFunc
  }), [])
}