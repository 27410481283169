import { useAptosWallet } from '@razorlabs/wallet-kit'
import useSWR from 'swr'
import axios from 'axios'
import { useMemo } from 'react'
import { GetOrdersResponse } from '../../../interfaces/limit.ts'
import { LIMIT_ORDER_URL } from '@/constants/index.ts'

const fn = async ({
  statuses,
  account,
  page,
  pageSize,
}: {
  key: string
  statuses: string[]
  account?: string
  page: number
  pageSize: number
}) => {
  if (!account)
    return {
      orders: [],
      total: 0,
    }

  const response = await axios<GetOrdersResponse>(`${LIMIT_ORDER_URL}/api/v1/orders`, {
    params: {
      statuses: statuses.join(','),
      user: account,
      page: page,
      pageSize: pageSize,
    },
  })
  if (response.status === 200) {
    return {
      orders: response.data.data.orders,
      total: response.data.data.pagination.totalItems,
    }
  }
  return {
    orders: [],
    total: 0,
  }
}

export function useGetOrdersByUser(statuses: string[], page: number, pageSize: number) {
  const { account } = useAptosWallet()

  const cacheKey = `orders-status-${statuses.join('-')}-${account?.address}`

  const { data, error, isLoading, mutate } = useSWR(
    {
      key: cacheKey,
      statuses,
      account: account?.address,
      page,
      pageSize,
    },
    fn,
    {
      refreshInterval: 3000,
    },
  )

  return useMemo(() => {
    return { orders: data?.orders, total: data?.total, error, isLoading, reFetch: mutate }
  }, [data, error, isLoading, mutate])
}
