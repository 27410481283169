import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
export interface AppState {
  cancellingOrderIds: string[]
}

const initialState: AppState = {
  cancellingOrderIds: [],
}

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    updatePendingOrders: (state, action: PayloadAction<{ id: string; isRemove: boolean }>) => {
      const { isRemove, id } = action.payload
      state.cancellingOrderIds = isRemove
        ? state.cancellingOrderIds.filter((i) => i !== id)
        : state.cancellingOrderIds.concat(id)
    },
  },
})

export const { updatePendingOrders: updateLimitOrderKey } = appSlice.actions

export default appSlice.reducer
