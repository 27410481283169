import { useAppSelector } from '../redux/hooks'
import { useMemo } from 'react'
import { PartialRecord } from '../types.ts'
import { Fraction } from '../utils/fraction.ts'
import { divpowToFraction, mulpowToFraction } from '../utils/number.ts'
import useFullTokens from './useFullTokens.ts'
import { TokenWithBalance } from '../components/modals/ModalSelectTokenLimitOrder.tsx'
import { sortBalanceFn } from '@/hooks/common/token/useTokenBalance.ts'

export function useAssetsList(isFullList: boolean) {
  const { balance } = useAppSelector((state) => state.wallet)

  const { data: fullTokenData } = useFullTokens()
  const followingTokenData = useAppSelector((state) => state.token.followingTokenData)
  const followingPriceData = useAppSelector((state) => state.price.followingPriceData)

  const assets = useMemo(() => {
    const res: PartialRecord<string, TokenWithBalance> = {}
    for (const key of Object.keys(balance)) {
      let fractionalBalance: Fraction | undefined
      let fractionalBalanceUsd: Fraction | undefined
      const tokenBalance = balance[key]
      const tokenInfo = fullTokenData?.[key]
      if (fullTokenData && tokenBalance && tokenInfo) {
        fractionalBalance = divpowToFraction(tokenBalance, tokenInfo.decimals)
        if (fractionalBalance && followingPriceData[key]) {
          const fractionalPrice = mulpowToFraction(followingPriceData[key])
          fractionalBalanceUsd = fractionalBalance.multiply(fractionalPrice)
        }
        res[key] = {
          ...tokenInfo,
          id: key,
          name: tokenInfo.name,
          symbol: tokenInfo.symbol,
          decimals: tokenInfo.decimals,
          whitelisted: followingTokenData?.[key] ? followingTokenData[key].whitelisted : false,
          logoUrl: followingTokenData?.[key] ? followingTokenData[key].logoUrl : undefined,
          fractionalBalance,
          fractionalBalanceUsd,
          isFollowing: true,
        }
      }
    }

    if (isFullList && fullTokenData) {
      for (const key of Object.keys(fullTokenData)) {
        if (!res[key]) {
          const tokenInfo = fullTokenData[key]
          if (tokenInfo) {
            res[key] = {
              ...tokenInfo,
              id: key,
              name: tokenInfo?.name,
              symbol: tokenInfo?.symbol,
              decimals: tokenInfo?.decimals,
              whitelisted: followingTokenData?.[key] ? followingTokenData[key].whitelisted : false,
              logoUrl: followingTokenData?.[key] ? followingTokenData[key].logoUrl : undefined,
              fractionalBalance: new Fraction(0),
              fractionalBalanceUsd: new Fraction(0),
              isFollowing: true,
            }
          }
        }
      }
    }
    return res
  }, [isFullList, fullTokenData, balance, followingPriceData, followingTokenData])

  const assetTokenList = useMemo(() => {
    const list = Object.values(assets) as TokenWithBalance[]
    return list.sort(sortBalanceFn)
  }, [assets])

  return useMemo(() => {
    return {
      assetTokenList,
    }
  }, [assetTokenList])
}
