import { useCallback, useMemo, useState } from 'react'
import { useAptosWallet } from '@razorlabs/wallet-kit'
import { aptos, LIMIT_ORDER_CONTRACT } from '../../constants'
import { Ed25519PublicKey, InputGenerateTransactionPayloadData } from '@aptos-labs/ts-sdk'
import invariant from 'invariant'
import useLimitOrderNotificationFn from '../limit/useLimitOrderNotificationFn.tsx'

export function usePlaceOrder() {
  const [isPlacingOrder, setIsPlacingOrder] = useState(false)
  const { account, signAndSubmitTransaction } = useAptosWallet()
  const notify = useLimitOrderNotificationFn()

  const placeOrder = useCallback(
    async (args: {
      orderBookIdx: string
      isAsk: boolean
      size: string
      price: string
      baseToken: string
      quoteToken: string
    }) => {
      try {
        if (!account) return
        setIsPlacingOrder(true)

        const payload: InputGenerateTransactionPayloadData = {
          function: `${LIMIT_ORDER_CONTRACT}::order_book::place`,
          functionArguments: [args.orderBookIdx, args.isAsk ? 'true' : 'false', args.size, args.price],
          typeArguments: [args.baseToken, args.quoteToken],
        }
        console.log(payload)

        const transaction = await aptos.transaction.build.simple({
          sender: account.address,
          data: payload,
        })

        const simulateResponses = await aptos.transaction.simulate.simple({
          signerPublicKey: new Ed25519PublicKey(account.publicKey),
          transaction,
          options: { estimateMaxGasAmount: true },
        })

        console.log({ simulateResponses, payload })

        invariant(simulateResponses.length === 1, 'simulateResponse length should be 1')
        const simulateResponse = simulateResponses[0]
        invariant(simulateResponse.success, simulateResponse.vm_status)

        const submitResponse = await signAndSubmitTransaction({ payload })

        // eslint-disable-next-line @typescript-eslint/no-unsafe-enum-comparison
        invariant(submitResponse.status === 'Approved', 'submitResponse.status REJECTED')
        const hash = submitResponse.args.hash
        notify('Place order', hash, true, '')
      } catch (err) {
        const jsonErr = JSON.stringify(err)
        if (jsonErr !== `{"code":"WALLET.SIGN_TX_ERROR"}`) {
          let errorDetails: string | undefined
          if (typeof err === 'string') {
            errorDetails = err
          } else {
            errorDetails = (err as any)?.message || undefined
          }
          notify('Place order', undefined, false, errorDetails)
        }
      } finally {
        setIsPlacingOrder(false)
      }
    },
    [account, notify, signAndSubmitTransaction],
  )

  return useMemo(() => ({ isPlacingOrder, placeOrder }), [isPlacingOrder, placeOrder])
}
