import { TokenImage } from '../../../components/TokenImage.tsx'
import { Body0, Body4 } from '../../../components/Typography.tsx'
import { useTokenInOutInfo } from '@/hooks/limit/useTokenInOutInfo.ts'
import { GetUserActivePositionResponseData } from '@/hooks/liquidity/useGetUserActivePosition.ts'

export function PositionItemTitle({ data: { pool } }: { data: GetUserActivePositionResponseData }) {
  const { tokenX, tokenY } = pool
  const { tokenInInfo: token0Info, tokenOutInfo: token1Info } = useTokenInOutInfo(tokenX, tokenY)

  return (
    <div className="flex w-full items-center justify-between">
      <div className="flex items-center gap-1">
        <div className="ml-1.5 flex">
          <TokenImage width={20} height={20} className="min-h-[20px] min-w-[20px]" logoUrl={token0Info?.logoUrl} />
          <TokenImage
            width={20}
            height={20}
            className="-ml-2 min-h-[20px] min-w-[20px]"
            logoUrl={token1Info?.logoUrl}
          />
        </div>
        <Body0 className="mr-1.5 whitespace-nowrap text-baseGrey">
          {token0Info?.symbol ?? '--'}/{token1Info?.symbol ?? '--'}
        </Body0>
      </div>
      <Body4 className="rounded-md px-2 py-1 text-primary">Manage</Body4>
    </div>
  )
}
