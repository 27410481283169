import { Image } from '@nextui-org/react'
import { useEffect, useState } from 'react'

export function TokenImage(
  {
    width, height,
    className,
    logoUrl
  }: { width?: number, height?: number, className?: string, logoUrl?: string }) {
  const [tokenLogoSrc, setTokenLogoSrc] = useState(logoUrl || '/images/404.svg')

  useEffect(() => {
    setTokenLogoSrc(logoUrl || '/images/404.svg')
  }, [logoUrl])

  return <Image
    width={width || 20}
    height={height || 20}
    className={className || 'min-h-[20px] min-w-[20px]'}
    src={tokenLogoSrc}
    onError={() => setTokenLogoSrc('/images/404.svg')}
  />
}