import axios from 'axios'
import { useMemo } from 'react'
import useSWR from 'swr'
import { AGGREGATOR_API_KEY, AGGREGATOR_URL } from '../constants'
import { Asset, AssetFromGit } from '../constants/asset'
import { PartialRecord } from '../types'

interface GetTokenInfoResponseData {
  tokenById: PartialRecord<string, Asset>
  nextCursor: number
}

interface GetTokenInfoResponse {
  code: number
  message: string
  data: GetTokenInfoResponseData
  requestId: string
}

const CHUNK_SIZE = 1e4

const fn = async () => {
  let cursor = 0
  const data: PartialRecord<string, Asset> = {}
  while (true) {
    const url = `${AGGREGATOR_URL}/v1/tokens?count=${CHUNK_SIZE}&cursor=${cursor}`
    const response = await axios<GetTokenInfoResponse>(url, {
      headers: {
        'X-API-KEY': AGGREGATOR_API_KEY,
      },
    })
    if (response.status === 200) {
      const tokens = response.data.data.tokenById ?? {}
      Object.keys(tokens).forEach((key) => {
        const token = tokens[key]
        data[key] = token
        if (token?.coinType) data[token.coinType] = { ...token, type: 'legacy' }
      })
      if (response.data.data.nextCursor) {
        cursor = response.data.data.nextCursor
        continue
      }
      break
    }
  }
  return data
}

const fetchWhitelist = async () => {
  // const tokenList = [
  //   {
  //     faAddress: '0xa',
  //     coinType: '0x1::aptos_coin::AptosCoin',

  //     symbol: 'MOVE',
  //     name: 'Movement Coin',
  //     decimals: 8,
  //     logoUrl: 'https://raw.githubusercontent.com/razorlabsorg/chainlist/main/chain/aptos/asset/MOVE.png',
  //   },
  //   {
  //     faAddress: '0xe161897670a0ee5a0e3c79c3b894a0c46e4ba54c6d2ca32e285ab4b01eb74b66',
  //     coinType: '0x275f508689de8756169d1ee02d889c777de1cebda3a7bbcce63ba8a27c563c6f::tokens::USDT',

  //     symbol: 'USDT',
  //     name: 'Tether USD',
  //     decimals: 6,
  //     logoUrl: 'https://raw.githubusercontent.com/razorlabsorg/chainlist/main/chain/aptos/asset/USDT.png',
  //   },
  //   {
  //     faAddress: '0x1e74c3312b1a7a08eb7cf61310787597ea6609d6d99ce86c0e48399144ea4ce9',
  //     coinType: '0x275f508689de8756169d1ee02d889c777de1cebda3a7bbcce63ba8a27c563c6f::tokens::USDC',

  //     symbol: 'USDC',
  //     name: 'USD Coin',
  //     decimals: 6,
  //     logoUrl: 'https://raw.githubusercontent.com/razorlabsorg/chainlist/main/chain/aptos/asset/USDC.png',
  //   },
  //   {
  //     faAddress: '0xa484a866e1bfcb76e8057939d6944539070b53c511813d7b21c76cae9e6a6e26',
  //     coinType: '0x275f508689de8756169d1ee02d889c777de1cebda3a7bbcce63ba8a27c563c6f::tokens::WETH',

  //     symbol: 'WETH',
  //     name: 'Wrapped Ether',
  //     decimals: 8,
  //     logoUrl: 'https://raw.githubusercontent.com/razorlabsorg/chainlist/main/chain/aptos/asset/WETH.png',
  //   },
  //   {
  //     faAddress: '0x6e8aae037a65e90ada6715e9108fb6d585a71c72d06f3963aae94e7845f24f02',
  //     coinType: '0x275f508689de8756169d1ee02d889c777de1cebda3a7bbcce63ba8a27c563c6f::tokens::WBTC',

  //     symbol: 'WBTC',
  //     name: 'Wrapped Bitcoin',
  //     decimals: 8,
  //     logoUrl: 'https://raw.githubusercontent.com/razorlabsorg/chainlist/main/chain/aptos/asset/WBTC.png',
  //   },
  //   {
  //     logoUrl: '/images/tokens/neth.png',
  //     decimals: 8,
  //     name: 'Nexus ETH',
  //     symbol: 'nETH',
  //     faAddress: '0x85913d9a4dc64d7d20cf3093729afe394a53cc553928631bff4f1031359c79c9',
  //   },
  // ]
  const { data: tokenList } = await axios.get<AssetFromGit[]>(
    `https://raw.githubusercontent.com/kitelabs-io/mvmt-tokens/main/token-list-porto.json`,
  )
  return tokenList.reduce<PartialRecord<string, Asset>>((rs, token) => {
    const asset = { ...token, whitelisted: true, id: token.faAddress } as Asset
    rs[token.faAddress] = asset
    return rs
  }, {})
}
export default function useFullTokens() {
  const { data, error, isValidating } = useSWR({ key: 'useFullTokens' }, fn, {
    revalidateIfStale: false,
    revalidateOnFocus: false,
    revalidateOnMount: true,
    revalidateOnReconnect: false,
  })

  const res = useMemo(
    () => ({
      isValidating,
      error,
      data,
    }),
    [data, error, isValidating],
  )

  return res
}

export function useWhitelistTokens() {
  const res = useSWR({ key: 'useWhitelistTokens' }, fetchWhitelist, {
    revalidateIfStale: false,
    revalidateOnFocus: false,
    revalidateOnMount: true,
    revalidateOnReconnect: false,
  })
  return res
}
