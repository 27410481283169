import { Tab, Tabs } from '@nextui-org/react'
import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

export function SwapLimitTab() {
  const [tab, setTab] = useState('swap');
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (location.pathname.includes('limit')) {
      setTab('limit')
    } else {
      setTab('swap')
    }
  }, [location.pathname])

  return (
    <Tabs classNames={{
      tabList: 'p-0 rounded-md gap-0',
      tabContent: 'group-data-[selected=true]:text-black2',
    }} color={'primary'} selectedKey={tab} onSelectionChange={(value) => {
      setTab(value as string)
      const path = location.pathname.replace(`/${tab}/`, '')
      navigate(`/${value}/${path}`)
    }}>
      <Tab key="swap" title="Swap"/>
      <Tab key="limit" title="Limit"/>
    </Tabs>
  );
}