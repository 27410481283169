import useSWR from 'swr'
import { aptos, LIMIT_ORDER_CONTRACT, LIMIT_ORDER_URL } from '../../constants'
import { useMemo } from 'react'
import axios from 'axios'

export interface OrderBookConfig {
  base_type_name: string
  idx: string
  lot_size: string
  quote_type_name: string
  tick_size: string
}

const fn = async ({ token0, token1 }: { token0: string; token1: string }) => {
  const token_0 = token0 as `${string}::${string}::${string}`
  const token_1 = token1 as `${string}::${string}::${string}`
  const result: { vec: string[] }[] = await aptos.viewJson({
    payload: {
      function: `${LIMIT_ORDER_CONTRACT}::order_book::get_order_book_idx`,
      typeArguments: [token_0, token_1],
    },
  })
  const idx = result[0].vec[0]
  const orderBookConfig: OrderBookConfig[] = await aptos.viewJson({
    payload: {
      function: `${LIMIT_ORDER_CONTRACT}::order_book::get_order_book`,
      functionArguments: [idx],
    },
  })
  return orderBookConfig[0]
}

export function useGetOrderBookConfig(token0: string, token1: string) {
  const {
    data: orderBookConfig,
    error,
    isValidating,
  } = useSWR(
    {
      key: `orderBookConfig-${token0}-${token1}`,
      token0,
      token1,
    },
    fn,
  )

  return useMemo(() => {
    return { orderBookConfig, error, isValidating }
  }, [orderBookConfig, error, isValidating])
}

const fetchMultiConfig = async () => {
  const { data } = await axios<{
    data: {
      idx: number
      baseTokenType: string
      quoteTokenType: string
      lotSize: string
      tickSize: string
      minSize: string
    }[]
  }>(`${LIMIT_ORDER_URL}/api/v1/order-books?limit=100&offset=0`)
  return (data?.data ?? []).map((e) => ({
    base_type_name: e.baseTokenType,
    idx: e.idx,
    lot_size: e.lotSize,
    quote_type_name: e.quoteTokenType,
    tick_size: e.tickSize,
  }))
}

export function useGetOrderBookConfigs() {
  const { data: orderbookConfig, isLoading } = useSWR({ key: 'orderBookConfigs' }, fetchMultiConfig)

  return useMemo(() => {
    return {
      isLoading,
      orderbookConfig:
        orderbookConfig?.reduce<Record<string, OrderBookConfig>>((acc, cur) => ({ ...acc, [cur.idx]: cur }), {}) || {},
    }
  }, [orderbookConfig, isLoading])
}
