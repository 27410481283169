import { Button, Spacer } from '@nextui-org/react'
import { MODAL_LIST } from '../components/modals/constant.ts'
import { SwapIcon } from '../components/Icons.tsx'
import { numberWithCommas, truncateValue } from '../utils/number.ts'
import { NumericFormat } from 'react-number-format'
import { useCallback } from 'react'
import { useTokenInOutInfo } from '../hooks/limit/useTokenInOutInfo.ts'
import { useTokenInOutPrice } from '../hooks/limit/useTokenInOutPrice.ts'
import { useTokenInOutBalance } from '../hooks/limit/useTokenInOutBalance.ts'
import { useSetTypedAmount } from '../hooks/useSetTypedAmount.ts'
import { useParseTokenInOut } from '../hooks/limit/useParseTokenInOut.ts'
import { useAmount } from '../hooks/limit/useAmount.ts'
import { useButtonText } from '../hooks/limit/useButtonText.ts'
import { useSetPercentAmountIn } from '../hooks/limit/useSetPercentAmountIn.ts'
import { useSetTypedTargetPrice } from '../hooks/limit/useSetTypedTargetPrice.ts'
import { usePlaceOrder } from '../hooks/contract/usePlaceOrder.ts'
import { useGetOrderBookConfig } from '../hooks/contract/useGetOrderBookConfig.ts'
import { SwapLimitTab } from '../components/SwapLimitTab.tsx'
import { LimitOrders } from '../components/limit-order/LimitOrders.tsx'
import ModalSelectTokenLimitOrder from '../components/modals/ModalSelectTokenLimitOrder.tsx'
import { usePrecheckAptosWallet } from '../hooks/usePrecheckAptosWallet.ts'
import { ButtonBase } from '../components/Button.tsx'
import { Body2 } from '../components/Typography.tsx'
import { useModal } from '../provider/ModalProvider.tsx'
import { SelectTokenInput } from '@/components/SelectTokenInput.tsx'

export default function LimitPage() {
  const { globalModal, isModalOpen, onOpenModal, onCloseModal, onOpenChangeModal } = useModal()

  const connected = usePrecheckAptosWallet()

  const { tokenInSymbolOrAddress, tokenOutSymbolOrAddress, redirectPair } = useParseTokenInOut('/limit')

  const { tokenIn, tokenOut, tokenInInfo, tokenOutInfo, tokenInDecimals, tokenOutDecimals } = useTokenInOutInfo(
    tokenInSymbolOrAddress,
    tokenOutSymbolOrAddress,
  )

  const { fractionalTokenInPriceTokenOut } = useTokenInOutPrice(tokenInSymbolOrAddress, tokenOutSymbolOrAddress)
  const { fractionalBalanceTokenIn, fractionalBalanceTokenOut } = useTokenInOutBalance(
    tokenInSymbolOrAddress,
    tokenOutSymbolOrAddress,
  )
  const { orderBookConfig } = useGetOrderBookConfig(tokenInInfo?.coinType ?? '', tokenOutInfo?.coinType ?? '')

  const {
    typedAmount: typedAmountIn,
    setTypedAmount: setTypedAmountIn,
    fractionalAmount: fractionalAmountIn,
  } = useSetTypedAmount('1', tokenInDecimals)

  const { typedTargetPrice, setTypedTargetPrice, fractionalTypedTargetPrice } = useSetTypedTargetPrice(
    '0',
    tokenOutDecimals,
    fractionalTokenInPriceTokenOut,
    tokenIn,
    tokenOut,
  )

  const {
    fractionalFeeAmount,
    fractionalAmountOut,
    fractionalAmountOutUsd,
    fractionalAmountInUsd,
    readableAmountOut,
    contractParamPrice,
    contractParamSize,
    isAsk,
  } = useAmount(tokenInSymbolOrAddress, tokenOutSymbolOrAddress, fractionalAmountIn, fractionalTypedTargetPrice)

  const onSetPercentAmountIn = useSetPercentAmountIn(fractionalBalanceTokenIn, fractionalFeeAmount, tokenInDecimals)
  const swapButton = useButtonText(fractionalAmountIn, fractionalBalanceTokenIn, fractionalFeeAmount, contractParamSize)
  const { isPlacingOrder, placeOrder } = usePlaceOrder()

  const switchToken = useCallback(() => {
    if (fractionalAmountOut && tokenOutDecimals) {
      setTypedAmountIn(truncateValue(fractionalAmountOut.toFixed(18), tokenOutDecimals), tokenOutDecimals)
    } else {
      setTypedAmountIn('')
    }
    redirectPair(tokenOutSymbolOrAddress, tokenInSymbolOrAddress)
  }, [
    fractionalAmountOut,
    redirectPair,
    setTypedAmountIn,
    tokenInSymbolOrAddress,
    tokenOutDecimals,
    tokenOutSymbolOrAddress,
  ])

  const setTokenIn = useCallback(
    (symbolOrAddress: string) => {
      if (tokenOut === symbolOrAddress || (tokenOutInfo && tokenOutInfo.symbol === symbolOrAddress)) {
        switchToken()
      } else {
        redirectPair(symbolOrAddress, tokenOutSymbolOrAddress)
      }
    },
    [tokenOut, tokenOutInfo, switchToken, redirectPair, tokenOutSymbolOrAddress],
  )

  const setTokenOut = useCallback(
    (symbolOrAddress: string) => {
      if (tokenIn === symbolOrAddress || (tokenInInfo && tokenInInfo.symbol === symbolOrAddress)) {
        switchToken()
      } else {
        redirectPair(tokenInSymbolOrAddress, symbolOrAddress)
      }
    },
    [tokenIn, tokenInInfo, switchToken, redirectPair, tokenInSymbolOrAddress],
  )

  return (
    <>
      <div className="z-[1] mt-4 w-full p-4 pt-0">
        <div className="mx-auto flex max-w-[1500px] flex-col lg:max-w-[464px]">
          <div className="flex justify-between gap-2">
            <SwapLimitTab />
          </div>
          <Spacer y={4} />

          <div className="flex gap-10 lg:flex-col">
            <div className="mx-auto flex max-w-[464px] flex-col">
              <div className="relative flex flex-col gap-1">
                {/* INPUT */}
                <>
                  <SelectTokenInput
                    tokenInInfo={tokenInInfo}
                    balance={fractionalBalanceTokenIn}
                    label="You are Selling"
                    onMax={() => onSetPercentAmountIn(100, setTypedAmountIn)}
                    connected={!!connected}
                    inputValue={typedAmountIn}
                    onChangeInput={(e) => setTypedAmountIn(e.currentTarget.value, tokenInDecimals)}
                    amountUsd={fractionalAmountInUsd}
                    onClickToken={() => onOpenModal(MODAL_LIST.SELECT_TOKEN_IN)}
                  />
                </>
                <div className="absolute left-1/2 top-1/2 z-[1] -translate-x-1/2 -translate-y-1/2">
                  <Button
                    isIconOnly
                    className="rounded-full border-4 border-[#252831] bg-[#33363F]"
                    onPress={switchToken}
                  >
                    <SwapIcon size={24} color="#FFFFFF" />
                  </Button>
                </div>
                {/* OUTPUT */}
                <SelectTokenInput
                  tokenInInfo={tokenOutInfo}
                  balance={fractionalBalanceTokenOut}
                  label="To Buy"
                  connected={!!connected}
                  inputValue={readableAmountOut}
                  onChangeInput={() => {}}
                  amountUsd={fractionalAmountOutUsd}
                  onClickToken={() => onOpenModal(MODAL_LIST.SELECT_TOKEN_OUT)}
                  disabled
                />
              </div>

              <Spacer y={1} />

              <>
                <div className=" flex flex-col gap-2 rounded-lg">
                  <div className="flex h-[24px] items-center justify-between">
                    <div className="text-white">Sell when 1 {tokenInInfo?.symbol} worth</div>
                    {connected && (
                      <Button
                        className="flex h-fit w-fit min-w-fit items-center gap-1 rounded-md bg-transparent p-0 disabled:opacity-100"
                        onPress={() => {
                          setTypedTargetPrice(fractionalTokenInPriceTokenOut?.toSignificant(6) ?? '', tokenOutDecimals)
                        }}
                      >
                        <div className=" p-1 text-primary">Market</div>
                      </Button>
                    )}
                  </div>
                  <div className="flex items-center justify-between gap-3 rounded-lg  border-[0.5px] border-borderGrey2 bg-baseGrey1 p-3">
                    <NumericFormat
                      decimalSeparator="."
                      allowedDecimalSeparators={[',']}
                      thousandSeparator
                      inputMode="decimal"
                      autoComplete="off"
                      autoCorrect="off"
                      type="text"
                      placeholder="0.00"
                      minLength={1}
                      maxLength={30}
                      spellCheck="false"
                      className="w-full bg-transparent text-[36px] font-medium leading-[42px] text-white outline-none placeholder:text-baseGrey sm:text-[30px]"
                      pattern="^[0-9]*[.,]?[0-9]*$"
                      value={typedTargetPrice}
                      allowNegative={false}
                      onChange={(e) => setTypedTargetPrice(e.currentTarget.value, tokenOutDecimals)}
                    />
                    <div className="whitespace-nowrap">{tokenOutInfo?.symbol ?? '--'}</div>
                  </div>
                  <div className="flex items-center justify-between gap-3">
                    <div className="flex items-center justify-between gap-1">
                      <div className="text-white">
                        {fractionalAmountOutUsd
                          ? '~$' + numberWithCommas(fractionalAmountOutUsd.toSignificant(6), false, 2)
                          : '--'}
                      </div>
                    </div>
                  </div>
                </div>
              </>
              <Spacer y={4} />

              {connected ? (
                <ButtonBase
                  v={'primary'}
                  className="h-[48px] min-h-[48px] w-full gap-0 rounded-[8px]"
                  isDisabled={swapButton.isDisabled}
                  isLoading={isPlacingOrder}
                  onClick={async () => {
                    await placeOrder({
                      orderBookIdx: orderBookConfig?.idx || '0',
                      isAsk: isAsk,
                      baseToken: orderBookConfig?.base_type_name || '',
                      quoteToken: orderBookConfig?.quote_type_name || '',
                      price: parseInt(contractParamPrice?.toSignificant(6) || '0').toString(),
                      size: parseInt(contractParamSize?.toSignificant(6) || '0').toString(),
                    })
                  }}
                >
                  <div className=" ">{swapButton.text}</div>
                </ButtonBase>
              ) : (
                <ButtonBase
                  v="primary"
                  className="h-[48px] min-h-[48px] w-full gap-0 rounded-[8px]"
                  onPress={() => onOpenModal(MODAL_LIST.CONNECT_WALLET)}
                >
                  <Body2>Connect Wallet</Body2>
                </ButtonBase>
              )}
            </div>
            <div className="flex w-full flex-col gap-3">
              <LimitOrders />
            </div>
          </div>
        </div>
      </div>
      <ModalSelectTokenLimitOrder
        isOpen={globalModal === MODAL_LIST.SELECT_TOKEN_IN && isModalOpen}
        onOpenChange={onOpenChangeModal}
        onClose={onCloseModal}
        setToken={setTokenIn}
      />
      <ModalSelectTokenLimitOrder
        isOpen={globalModal === MODAL_LIST.SELECT_TOKEN_OUT && isModalOpen}
        onOpenChange={onOpenChangeModal}
        onClose={onCloseModal}
        setToken={setTokenOut}
      />
    </>
  )
}
