import { Accordion, AccordionItem, Button, Spinner } from '@nextui-org/react'
import { Headline5 } from '../../../components/Typography.tsx'
import { useLocation, useNavigate } from 'react-router-dom'
import { PositionItem } from './PositionItem.tsx'
import { Icon } from '@iconify/react'
import { PositionItemTitle } from './PosititonItemTitle.tsx'
import { useGetUserActivePosition } from '@/hooks/liquidity/useGetUserActivePosition.ts'
import { NoPools } from '@/pages/liquidity/my-positions/NoPools.tsx'
import MainMotion from '@/components/anim/MainMotion.tsx'
import { Fragment } from 'react/jsx-runtime'
import PageLayout from '@/components/PageLayout.tsx'

export const LiquidityTabs = () => {
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const tabs = [
    { name: 'My Positions', path: '/liquidity/my-positions' },
    { name: 'Liquidity Pools', path: '/liquidity/explore' },
  ]
  return (
    <div className="mb-[18px] flex gap-3">
      {tabs.map((item, index) => {
        const selected = pathname === item.path
        return (
          <Fragment key={index}>
            <Button
              onClick={() => (selected ? null : navigate(item.path))}
              className="h-[24px] rounded-none bg-transparent p-0"
              disableAnimation
            >
              <Headline5 className={selected ? 'text-primary' : 'text-baseGrey'}>{item.name}</Headline5>
            </Button>
            {index !== tabs.length - 1 && <div className="h-[24px] w-[2px] bg-borderGrey2" />}
          </Fragment>
        )
      })}
    </div>
  )
}

export default function MyPositions() {
  const { getUserActivePosition: userActivePosition, isLoading: isValidating } = useGetUserActivePosition()

  const positions = userActivePosition?.data?.result ?? []

  return (
    <PageLayout>
      <LiquidityTabs />

      <MainMotion className="flex flex-col gap-2">
        {isValidating ? (
          <div className="flex min-h-[200px] justify-center">
            <Spinner label="Loading..." />
          </div>
        ) : !positions.length ? (
          <NoPools />
        ) : (
          positions.map((pool) => (
            <Accordion
              key={pool.poolAddr}
              variant="splitted"
              className="rounded-xl border-[0.5px] border-borderGrey2 bg-baseGrey1 p-0"
            >
              <AccordionItem
                key={pool.poolAddr}
                aria-label="Accordion 1"
                title={<PositionItemTitle data={pool} />}
                indicator={({ isOpen }) => (isOpen ? <Icon icon="mdi:chevron-up" /> : <Icon icon="mdi:chevron-down" />)}
              >
                <PositionItem data={pool} />
              </AccordionItem>
            </Accordion>
          ))
        )}
      </MainMotion>
    </PageLayout>
  )
}
