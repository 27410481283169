import { useAppSelector } from '../../../redux/hooks'
import { useMemo } from 'react'
import { mulpowToFraction } from '../../../utils/number.ts'
import { useTokenInfo } from './useTokenInfo.ts'

export function useTokenPrice(tokenSymbolOrAddress: string) {
  const { tokenAddress } = useTokenInfo(tokenSymbolOrAddress)
  const followingPriceData = useAppSelector((state) => state.price.followingPriceData)

  const fractionalPriceToken = useMemo(
    () => (followingPriceData[tokenAddress] ? mulpowToFraction(followingPriceData[tokenAddress]) : undefined),
    [followingPriceData, tokenAddress]
  )

  return useMemo(() => fractionalPriceToken, [fractionalPriceToken])
}