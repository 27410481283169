import { useTokenInOutInfo } from './useTokenInOutInfo.ts'
import { useTokenBalance } from '@/hooks/common/token/useTokenBalance.ts'

export function useTokenInOutBalance(tokenSymbolOrAddress0: string, tokenSymbolOrAddress1: string) {
  const { tokenIn, tokenOut } = useTokenInOutInfo(tokenSymbolOrAddress0, tokenSymbolOrAddress1)

  const { tokenBalance: balanceTokenIn, fractionalTokenBalance: fractionalBalanceTokenIn } = useTokenBalance(tokenIn)
  const { tokenBalance: balanceTokenOut, fractionalTokenBalance: fractionalBalanceTokenOut } = useTokenBalance(tokenOut)

  return { balanceTokenIn, balanceTokenOut, fractionalBalanceTokenIn, fractionalBalanceTokenOut }
}
