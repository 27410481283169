import { useCallback } from 'react'
import { aptos } from '../constants'
import { useAppDispatch, useAppSelector } from '../redux/hooks'
import { addTokensToFollow } from '../redux/slices/token'
import { updateBalance, WalletBalance } from '../redux/slices/wallet'
import useMovementWallet from './useMovementWallet.ts'
import { Asset } from '../constants/asset.ts'
import { getTokenAddress } from '@/utils/token.ts'

export interface GetAccountCoinResourceResponse {
  type: string
  data: {
    coin: {
      value: string
    }
  }
}

export default function useRefreshBalanceFn() {
  const dispatch = useAppDispatch()
  const { account } = useMovementWallet()

  const fn = useCallback(async () => {
    if (!account) return
    try {
      const data = await aptos.getAccountCoinsData({
        accountAddress: account.address,
        options: {
          where: {
            owner_address: { _eq: account.address },
          },
          offset: 0,
          limit: 100,
        },
      })
      const accountCoinsData: WalletBalance = {}
      const accountFaData: WalletBalance = {}
      data.forEach((item) => {
        if (!item.asset_type || !item.amount) return
        const key =
          item.asset_type === '0x000000000000000000000000000000000000000000000000000000000000000a'
            ? '0xa'
            : item.asset_type

        if (item.token_standard === 'v2') accountFaData[key] = item.amount
        else accountCoinsData[key] = item.amount
      })

      dispatch(updateBalance({ coinBalance: accountCoinsData, balance: accountFaData }))
      dispatch(addTokensToFollow(Object.keys({ ...accountCoinsData, ...accountFaData }).map((coinType) => coinType)))
    } catch (err) {
      console.error(err)
    }
  }, [account, dispatch])

  return fn
}

export const useGetTokenBalance = () => {
  const { balance, coinBalance } = useAppSelector((state) => state.wallet)
  return useCallback(
    (token: Asset | undefined) =>
      (BigInt(balance?.[getTokenAddress(token)] || 0) + BigInt(coinBalance?.[token?.coinType ?? ''] || 0)).toString(),
    [balance, coinBalance],
  )
}

export const useTokenBalance = (token: Asset | undefined) => {
  const getBalance = useGetTokenBalance()
  return getBalance(token)
}
