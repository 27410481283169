import { NextUIProvider } from '@nextui-org/react'
import React from 'react'
import ReactDOM from 'react-dom/client'
import ReactGA from 'react-ga4'
import { Provider as ReduxProvider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'

import '@razorlabs/wallet-kit/style.css'
import 'react-toastify/dist/ReactToastify.css'
import 'react-tooltip/dist/react-tooltip.css'
import './main.css'
import './main.scss'
import { persistor, store } from './redux/store'
import App from './App.tsx'
import { ModalProvider } from './provider/ModalProvider.tsx'
import { ToastContainer } from 'react-toastify'
import Tooltips from './components/Tooltips.tsx'
import ModalConnectWallet from './components/modals/ModalConnectWallet.tsx'
import Updaters from './redux/updaters/Updaters.tsx'
import ModalAssetsAndActivities from './components/modals/ModalAssetsAndActivities.tsx'
import { AptosWalletProvider } from '@razorlabs/wallet-kit'
import { bitget, nightly, okx, razor } from './constants/wallet.ts'
import { MOVEMENT_RPC_URL } from './constants'

ReactGA.initialize('G-XLFPZGS0WB')

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <ReduxProvider store={store}>
      <NextUIProvider>
        <PersistGate persistor={persistor} loading={null}>
          <ModalProvider>
            <AptosWalletProvider
              defaultWallets={[razor, nightly, okx, bitget]}
              autoConnect={true}
              chains={[
                {
                  id: '177',
                  name: 'Movement Testnet',
                  rpcUrl: MOVEMENT_RPC_URL,
                },
              ]}
            >
              <Updaters>
                <App />
                <ToastContainer
                  autoClose={4000}
                  draggablePercent={50}
                  theme="dark"
                  position="top-right"
                  closeButton={false}
                  pauseOnHover={false}
                />
                <Tooltips />
                <ModalAssetsAndActivities />
                <ModalConnectWallet />
              </Updaters>
            </AptosWalletProvider>
          </ModalProvider>
        </PersistGate>
      </NextUIProvider>
    </ReduxProvider>
  </React.StrictMode>,
)
