import useSWR from 'swr'
import { useMemo } from 'react'
import { aptos } from '../../../constants'

const fn = async ({ pool }: { pool: string; }) => {
  const result: { vec: string[] }[] = await aptos.viewJson({
    payload: {
      function: `0x1::fungible_asset::supply`,
      functionArguments: [pool],
      typeArguments: ['0x1::fungible_asset::Metadata']
    }
  })
  return result[0].vec[0]
}

export function useGetPoolLiq(pool: string) {
  const { data: poolLiq, error, isValidating, mutate } = useSWR(
    {
      key: `poolLiq-${pool}}`,
      pool
    },
    fn
  )
  return useMemo(() => {
    return { poolLiq, error, isValidating, reFetch: mutate }
  }, [poolLiq, error, isValidating, mutate])


}