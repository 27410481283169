import { useCallback, useMemo } from 'react'
import { truncateValue } from '../../../utils/number.ts'
import { useTokenInfo } from './useTokenInfo.ts'
import { Fraction } from '../../../utils/fraction.ts'
import { Asset } from '@/constants/asset.ts'

export function useSwitchToken(
  token0SymbolOrAddress: string,
  token1SymbolOrAddress: string,
  fractionalAmountInToken0: Fraction | undefined,
  fractionalAmountInToken1: Fraction | undefined,
  redirectPair: (token0: string, token1: string) => void,
  setTypedAmountInToken0: (value: string, decimals?: number) => void,
  setTypedAmountInToken1: (value: string, decimals?: number) => void,
  callback: () => void,
) {
  const {
    tokenInfo: token0Info,
    tokenAddress: token0,
    tokenDecimals: token0Decimals,
  } = useTokenInfo(token0SymbolOrAddress)
  const {
    tokenInfo: token1Info,
    tokenAddress: token1,
    tokenDecimals: token1Decimals,
  } = useTokenInfo(token1SymbolOrAddress)

  const switchToken = useCallback(() => {
    if (fractionalAmountInToken1 && token1Decimals) {
      setTypedAmountInToken0(truncateValue(fractionalAmountInToken1.toFixed(18), token1Decimals), token1Decimals)
    } else {
      setTypedAmountInToken0('')
    }

    if (fractionalAmountInToken0 && token0Decimals) {
      setTypedAmountInToken1(truncateValue(fractionalAmountInToken0.toFixed(18), token0Decimals), token0Decimals)
    } else {
      setTypedAmountInToken1('')
    }

    redirectPair(token0Info?.symbol || token0, token1Info?.symbol || token1)
  }, [
    fractionalAmountInToken0,
    fractionalAmountInToken1,
    redirectPair,
    setTypedAmountInToken0,
    setTypedAmountInToken1,
    token0,
    token0Decimals,
    token0Info?.symbol,
    token1,
    token1Decimals,
    token1Info?.symbol,
  ])

  const setToken0 = useCallback(
    (token: Asset) => {
      const symbolOrAddress = token.whitelisted ? token.symbol : token.id
      if (token1 === symbolOrAddress || (token1Info && token1Info.symbol === symbolOrAddress)) {
        switchToken()
      } else {
        redirectPair(symbolOrAddress, token1Info?.symbol || token1)
      }
      callback()
    },
    [callback, redirectPair, switchToken, token1, token1Info],
  )

  const setToken1 = useCallback(
    (token: Asset) => {
      const symbolOrAddress = token.whitelisted ? token.symbol : token.id
      if (token0 === symbolOrAddress || (token0Info && token0Info.symbol === symbolOrAddress)) {
        switchToken()
      } else {
        redirectPair(token0Info?.symbol || token0, symbolOrAddress)
      }
      callback()
    },
    [callback, redirectPair, switchToken, token0, token0Info],
  )

  return useMemo(() => {
    return { switchToken, setToken0, setToken1 }
  }, [switchToken, setToken0, setToken1])
}
