import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useSetTypedAmount } from '../useSetTypedAmount.ts'
import { Fraction } from '../../utils/fraction.ts'

export function useSetTypedTargetPrice(
  defaultValue: string,
  tokenDecimals?: number,
  fractionalPriceTokenIn?: Fraction,
  tokenIn?: string,
  tokenOut?: string,
) {
  const [loading, setLoading] = useState(true)
  const {
    typedAmount: typedTargetPrice,
    setTypedAmount: setTypedTargetPrice,
    fractionalAmount: fractionalTypedTargetPrice,
  } = useSetTypedAmount(defaultValue, tokenDecimals)

  const isInited = useRef(false)

  const updateTypedTargetPrice = useCallback(() => {
    if (isInited.current) return
    if (fractionalPriceTokenIn) {
      isInited.current = true
      setTypedTargetPrice(fractionalPriceTokenIn.toSignificant(6), tokenDecimals)
    }
  }, [fractionalPriceTokenIn, setTypedTargetPrice, tokenDecimals])

  useEffect(() => {
    if (loading && fractionalPriceTokenIn) {
      updateTypedTargetPrice()
      setLoading(false)
    }
  }, [fractionalPriceTokenIn, loading, setTypedTargetPrice, tokenDecimals, updateTypedTargetPrice])

  useEffect(() => {
    updateTypedTargetPrice()
  }, [tokenIn, tokenOut, updateTypedTargetPrice])

  return useMemo(
    () => ({
      typedTargetPrice,
      setTypedTargetPrice,
      fractionalTypedTargetPrice,
      updateTypedTargetPrice,
      loading,
    }),
    [typedTargetPrice, setTypedTargetPrice, fractionalTypedTargetPrice, updateTypedTargetPrice, loading],
  )
}
