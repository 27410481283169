import { Body2, Subtitle2 } from '../../../components/Typography.tsx'
import { Button, Image } from '@nextui-org/react'
import { useNavigate } from 'react-router-dom'

export function NoPools() {
  const navigate = useNavigate()

  return <div
    className="flex flex-col items-center justify-center h-full bg-baseGrey1 border-[0.5px] border-borderGrey2 p-4 rounded-md">
    <Body2 className="text-baseGrey text-center">
      You don&apos;t have pool yet.
    </Body2>
    <Image className={'mt-4'} src={'/images/no_pool.svg'} width={200} height={200} />
    <Button color={'primary'} className="mt-4 w-full text-baseGrey1" onClick={() => {
      navigate('/liquidity/explore')
    }}>
      <Subtitle2>
        Explore Pool
      </Subtitle2>
    </Button>
  </div>

}