import { useTokenInOutInfo } from './useTokenInOutInfo.ts'
import { useAppSelector } from '../../redux/hooks'
import { useMemo } from 'react'
import { mulpowToFraction } from '../../utils/number.ts'

export function useTokenInOutPrice(tokenSymbolOrAddress0: string, tokenSymbolOrAddress1: string) {
  const {tokenIn, tokenOut} = useTokenInOutInfo(tokenSymbolOrAddress0, tokenSymbolOrAddress1);
  const followingPriceData = useAppSelector((state) => state.price.followingPriceData)
  const fractionalPriceTokenIn = useMemo(
    () => (followingPriceData[tokenIn] ? mulpowToFraction(followingPriceData[tokenIn]) : undefined),
    [followingPriceData, tokenIn]
  )
  const fractionalPriceTokenOut = useMemo(
    () => (followingPriceData[tokenOut] ? mulpowToFraction(followingPriceData[tokenOut]) : undefined),
    [followingPriceData, tokenOut]
  )

  const fractionalTokenInPriceTokenOut = useMemo(
    () => (fractionalPriceTokenIn && fractionalPriceTokenOut ? fractionalPriceTokenIn.divide(fractionalPriceTokenOut) : undefined),
    [fractionalPriceTokenIn, fractionalPriceTokenOut]
  )

  const fractionalTokenOutPriceTokenIn = useMemo(
    () => (fractionalPriceTokenIn && fractionalPriceTokenOut ? fractionalPriceTokenOut.divide(fractionalPriceTokenIn) : undefined),
    [fractionalPriceTokenIn, fractionalPriceTokenOut]
  )

  return useMemo(() => ({
    fractionalPriceTokenIn,
    fractionalPriceTokenOut,
    fractionalTokenInPriceTokenOut,
    fractionalTokenOutPriceTokenIn,
  }), [fractionalPriceTokenIn, fractionalPriceTokenOut, fractionalTokenInPriceTokenOut, fractionalTokenOutPriceTokenIn])
}
