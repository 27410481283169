import { useCallback } from 'react'
import { useAptosWallet } from '@razorlabs/wallet-kit'
import { aptos, LIMIT_ORDER_CONTRACT } from '../../constants'
import { Ed25519PublicKey } from '@aptos-labs/ts-sdk'
import invariant from 'invariant'
import useLimitOrderNotificationFn from '../limit/useLimitOrderNotificationFn.tsx'
import { usePendingOrders } from '../../redux/hooks/index.ts'

export function useCancelOrder() {
  const { account, signAndSubmitTransaction } = useAptosWallet()
  const notify = useLimitOrderNotificationFn()
  const { addPendingOrders, removePendingOrders } = usePendingOrders()

  return useCallback(
    async (args: { id: string; baseToken: string; quoteToken: string }) => {
      try {
        if (!account) return
        addPendingOrders(args.id)
        const payload = {
          function: `${LIMIT_ORDER_CONTRACT}::order_book::cancel` as `${string}::${string}::${string}`,
          functionArguments: [args.id],
          typeArguments: [args.baseToken, args.quoteToken],
        }

        const transaction = await aptos.transaction.build.simple({
          sender: account.address,
          data: payload,
        })

        const simulateResponses = await aptos.transaction.simulate.simple({
          signerPublicKey: new Ed25519PublicKey(account.publicKey),
          transaction,
          options: { estimateMaxGasAmount: true },
        })

        invariant(simulateResponses.length === 1, 'simulateResponse length should be 1')
        const simulateResponse = simulateResponses[0]
        invariant(simulateResponse.success, simulateResponse.vm_status)

        const submitResponse = await signAndSubmitTransaction({ payload })
        // eslint-disable-next-line @typescript-eslint/no-unsafe-enum-comparison
        invariant(submitResponse.status === 'Approved', 'submitResponse.status REJECTED')
        const hash = submitResponse.args.hash
        notify('Cancel order', hash, true, '')
      } catch (err) {
        const jsonErr = JSON.stringify(err)
        removePendingOrders(args.id)
        if (jsonErr !== `{"code":"WALLET.SIGN_TX_ERROR"}`) {
          let errorDetails: string | undefined
          if (typeof err === 'string') {
            errorDetails = err
          } else {
            errorDetails = (err as any)?.message || undefined
          }
          notify('Place order', undefined, false, errorDetails)
        }
      }
    },
    [account, notify, removePendingOrders, addPendingOrders, signAndSubmitTransaction],
  )
}
