import { Pagination, Spinner, Table, TableBody, TableCell, TableColumn, TableHeader, TableRow } from '@nextui-org/react'
import { ReactElement, useCallback, useMemo, useState } from 'react'
import { useGetOrdersByUser } from '../../hooks/limit/api/useGetOrdersByUser.ts'
import { CancelledIcon, CompletedIcon, PartiallyFilledIcon } from '../Icons.tsx'
import { calcPriceAndSize, FillProgress, OrderRate, TokenAmount } from '@/components/limit-order/OpenOrders.tsx'
import { useGetOrderBookConfigs } from '@/hooks/contract/useGetOrderBookConfig.ts'
import { useWhitelistTokens } from '@/hooks/useFullTokens.ts'

export const useGetTokenInfoById = () => {
  const { data } = useWhitelistTokens()
  return useCallback(
    (tokenType: string) => Object.values(data ?? {}).find((token) => token?.coinType === tokenType),
    [data],
  )
}

export function OrdersHistory() {
  const [page, setPage] = useState(1)
  const pageSize = useMemo(() => 5, [])
  const { orders, total, isLoading: isLoadingOrder } = useGetOrdersByUser(['FILLED', 'CANCELLED'], page, pageSize)
  const { orderbookConfig, isLoading: isLoadingConfig } = useGetOrderBookConfigs()
  const isLoading = isLoadingConfig || isLoadingOrder

  const classNames = useMemo(
    () => ({
      base: ['gap-0'],
      wrapper: ['bg-dark0', 'p-0', 'gap-0', 'border-x border-t border-ffffff20', 'rounded-none', 'rounded-t-large'],
      th: ['bg-black2', 'text-base', 'text-light2', 'font-semibold', 'px-5 py-2'],
      tr: ['border-b', 'border-ffffff20', 'rounded-none'],
      td: ['px-5 py-2'],
    }),
    [],
  )

  const getTokenInfoById = useGetTokenInfoById()
  const tableRowRender = useMemo(() => {
    if (isLoading) {
      return []
    }

    const rows: ReactElement[] = []
    orders?.forEach((item, index) => {
      const baseTokenInfo = getTokenInfoById(item.baseTokenType)
      const quoteTokenInfo = getTokenInfoById(item.quoteTokenType)
      const { displayPrice, displaySize, percent } = calcPriceAndSize({
        item,
        orderbookConfig,
        baseTokenInfo,
        quoteTokenInfo,
      })

      const props = { item, baseTokenInfo, quoteTokenInfo, displayPrice, displaySize, percent }
      const row = (
        <TableRow key={index} className="cursor-pointer text-white">
          <TableCell>
            <TokenAmount {...props} />
          </TableCell>
          <TableCell>
            <OrderRate {...props} />
          </TableCell>
          <TableCell>
            <FillProgress {...props} />
          </TableCell>
          <TableCell className="flex justify-end text-right">
            {percent === 0 && (
              <div className="flex gap-1">
                <span className="text-[#E7455F]">Cancelled</span> <CancelledIcon />
              </div>
            )}
            {percent === 1 && (
              <div className="flex gap-1">
                <span className="text-[#18CF6A]">Completed</span> <CompletedIcon />
              </div>
            )}
            {percent !== 0 && percent !== 1 && (
              <div className="flex gap-1">
                <span className="text-primary">Partially Filled </span>
                <PartiallyFilledIcon />
              </div>
            )}
          </TableCell>
        </TableRow>
      )
      rows.push(row)
    })
    return rows
  }, [isLoading, orders, orderbookConfig, getTokenInfoById])

  return (
    <>
      <Table
        aria-label="open-order-table"
        classNames={classNames}
        bottomContent={
          !isLoading && (
            <div className=" flex items-center justify-center rounded-b-large border-x border-b px-2 py-2">
              <Pagination
                showControls
                classNames={{
                  cursor: ['cursor-pointer text-dark0'],
                }}
                onChange={(page) => {
                  setPage(page)
                }}
                page={page}
                total={Math.ceil((total || 0) / pageSize)}
                variant="light"
              />
            </div>
          )
        }
        bottomContentPlacement={'outside'}
      >
        <TableHeader>
          <TableColumn className="min-w-48 text-white">Order(s)</TableColumn>
          <TableColumn className="min-w-48 text-white">Rate</TableColumn>
          <TableColumn className="text-white">Total Filled</TableColumn>
          <TableColumn className="text-right text-white">Status</TableColumn>
        </TableHeader>
        <TableBody
          emptyContent={'No orders history'}
          isLoading={isLoading}
          loadingContent={<Spinner label="Loading..." />}
        >
          {tableRowRender.map((row) => row)}
        </TableBody>
      </Table>
    </>
  )
}
