import { useAptosWallet } from '@razorlabs/wallet-kit'
import { useEffect, useMemo } from 'react'

export function usePrecheckAptosWallet() {
  const { account, connected: _connected, disconnect } = useAptosWallet()
  const connected = useMemo(() => _connected && account?.address, [_connected, account])

  // Need this because razor extension is stupid
  useEffect(() => {
    if (!connected) {
      void disconnect()
    }
  }, [connected, disconnect])

  return connected
}