import { cn, Image, Switch } from '@nextui-org/react'
import MainMotion from '../../components/anim/MainMotion'
import { ALL_DEXES, POOLS_OPTIONS } from '../../constants/pool'
import { SettingHeader } from './SwapSettings'
import { ButtonBase } from '../../components/Button'
import { Fragment } from 'react/jsx-runtime'
import { useOnClickOutside } from 'usehooks-ts'
import { RefObject } from 'react'

const displayPools = (pool: string) => {
  return pool.replaceAll('_', ' ')
}

export default function DexSelector({
  setSource,
  source,
  onBack: _onBack,
  swapCardRef,
}: {
  setSource: (source: string) => void
  onBack: () => void
  source: string
  swapCardRef: RefObject<HTMLDivElement>
}) {
  const sources = source ? source.split(',') : []
  const onBack = () => {
    if (sources.length === 0) {
      setSource(ALL_DEXES.join(','))
    }
    _onBack()
  }

  const onChange = (value: string) => {
    const arr = sources.includes(value) ? sources.filter((op) => op !== value) : sources.concat(value)
    setSource(arr.join(','))
  }

  useOnClickOutside(swapCardRef, onBack)

  return (
    <MainMotion>
      <div className="flex flex-col gap-4 rounded-lg border-[0.5px] border-borderGrey2 bg-baseGrey1 p-3">
        <SettingHeader
          onBack={onBack}
          title="Liquidity Sources"
          action={
            <ButtonBase
              v="secondary"
              onPress={() => {
                if (sources.length === 0) setSource(ALL_DEXES.join(','))
                else setSource('')
              }}
              size="sm"
              color="secondary"
              className=" h-fit w-[30px] p-1 px-0"
            >
              Reset
            </ButtonBase>
          }
        />
        <div className=" flex w-full flex-col justify-center overflow-y-auto">
          {POOLS_OPTIONS.map((source) => {
            return (
              <Fragment key={source.options.join(',')}>
                {source.options.map((option) => {
                  return (
                    <div key={option} className="flex justify-between capitalize text-white">
                      <Switch
                        onChange={() => {
                          onChange(option)
                        }}
                        isSelected={sources.includes(option)}
                        size="sm"
                        classNames={{
                          base: cn(
                            'inline-flex flex-row-reverse w-full max-w-md bg-content1 items-center',
                            `justify-between cursor-pointer rounded-lg gap-2 p-1.5 px-0 ${option === ALL_DEXES[ALL_DEXES.length - 1] ? 'pb-0' : ''} mr-[-8px] border-2 border-transparent`,
                          ),
                          wrapper: 'p-0 h-4 overflow-visible mr-[0px]',
                          thumb: cn(
                            'w-5 h-5 border-2 shadow-lg',
                            //selected
                            'group-data-[selected=true]:ml-5',
                            // pressed
                            'group-data-[pressed=true]:w-7',
                            'group-data-[selected]:group-data-[pressed]:ml-4',
                          ),
                        }}
                      >
                        <div className="flex items-center gap-2">
                          <Image width={'16px'} src={source.logoUrl} />
                          <span className="text-[14px]">
                            {source.options.length === 1 ? source.name : displayPools(option)}
                          </span>
                        </div>
                      </Switch>
                    </div>
                  )
                })}
              </Fragment>
            )
          })}
        </div>
      </div>
    </MainMotion>
  )
}
