import { PartialRecord } from '../types.ts'

export const POOLS_OPTIONS = [
  {
    name: 'Meridian',
    shortName: 'Meridian',
    logoUrl: '/images/meridian.ico',
    options: ['meridian_stable', 'meridian_weighted'],
  },
  // {
  //   name: 'AnimeSwap',
  //   shortName: 'Anime',
  //   logoUrl: '/images/animeswap.ico',
  //   options: ['anime_swap_v1', 'anime_swap_v2'],
  // },
  { name: 'RazorSwap', shortName: 'Razor', logoUrl: '/images/razor.png', options: ['razor_swap'] },
  { name: 'LiquidSwap', shortName: 'Liquid', logoUrl: '/images/liquidswap.ico', options: ['liquid_swap'] },
  // { name: 'Mosaic', shortName: 'Mosaic', logoUrl: '/favicon.ico', options: ['mosaic_amm'] },
  {
    name: 'Interest',
    shortName: 'Interest',
    logoUrl: '/images/interest.png',
    options: ['interest_sr_amm', 'interest_stable', 'interest_volatile'],
  },
  {
    name: 'Warp Gate',
    shortName: 'Warp Gate',
    logoUrl: '/images/warp_gate.png',
    options: ['warp_gate_swap'],
  },
]

export const SUPPORTED_POOLS = POOLS_OPTIONS.reduce<
  PartialRecord<string, { name: string; shortName: string; logoUrl: string }>
>((rs, item) => {
  item.options.forEach((option) => {
    rs[option] = item
  })
  return rs
}, {})

export const ALL_DEXES = POOLS_OPTIONS.map((source) => source.options).flat()
